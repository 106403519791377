import React from "react";
import "../App.css";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import Avatar from "./Avatar";
import Icon from "@mui/material/Icon";
import Dropdown from "react-bootstrap/Dropdown";
import { IconButton } from "@mui/material";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);
const Networker = require("../Networker.js");

class ThreadCell extends React.Component {
  sendWellnessEmail() {
    MySwal.fire({
      title: "Are you sure to send wellness email to " + this.displayName,
      showLoaderOnConfirm: true,
      showCancelButton: true,
      confirmButtonText: "Send",
      preConfirm: async () => {
        await Networker.sendWellneshEmail(this.props.thread.thread_id);
      },
    });
  }
  render() {
    let user = this.props.thread.users.length > 0 ? this.props.thread.users[0] : null;
    var displayName = "(Not Set)";

    if (this.props.thread.users !== undefined && this.props.thread.users !== null) {
      if (this.props.thread.users.length > 0) {
        if (user.display_name !== "" && user.display_name !== null && user.display_name !== undefined) {
          displayName = "@" + this.props.thread.users[0].display_name;
        } else if (user.name !== "" && user.name !== null && user.name !== undefined) {
          displayName = user.name;
        }
      } else {
        displayName = "@Random_Lyfer";
      }
    } else {
      displayName = "@Random_Lyfer";
    }

    return (
      <ListItemButton selected={this.props.selected ? true : false} alignItems="flex-start" onClick={this.props.onClick}>
        {this.props.thread.users && (
          <ListItemAvatar>
            <Avatar user={user} />
          </ListItemAvatar>
        )}
        {this.props.thread.last_message && (
          <ListItemText
            primary={displayName}
            style={{ maxWidth: "200px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
            secondary={<React.Fragment>{this.props.thread.last_message.text}</React.Fragment>}
          />
        )}
        {this.props.thread.status_id === 1 && this.props.type === "active" && !this.props.thread.wellness_check && (
          <Dropdown className="menu">
            <Dropdown.Toggle as={Icon}>
              <p style={{ color: "#4ac4a2" }}>more_horiz</p>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => this.sendWellnessEmail()}>
                <IconButton size="small" className="pt-1">
                  <Icon>email</Icon>
                </IconButton>
                Send Wellness Email
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </ListItemButton>
    );
  }
}

export default ThreadCell;
