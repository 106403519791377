import React, { useState, useEffect } from "react";
import MessageCell from "./MessageCell.js";
import { useParams } from "react-router-dom";
import Loader from "react-loader-spinner";
import Collapse from "@mui/material/Collapse";
import useMediaQuery from "@mui/material/useMediaQuery";
import IconButton from "@mui/material/IconButton";
import { useAuth } from "../Contexts/authContext/index.jsx";

const Networker = require("../Networker.js");
const avatarIcon = require("../Assets/user.png");
function SharedSession() {
  const { setEmojis } = useAuth();
  const [thread, setThread] = useState(null);
  const [messages, setMessages] = useState(null);
  const [openProfile, setOpenProfile] = useState(true);
  const xlMatches = useMediaQuery("(min-width:1200px)");
  const mdMatches = useMediaQuery("(min-width:768px)");
  let { keyId, code } = useParams();
  useEffect(async () => {
    const fetchData = async () => {
      const data = await Networker.getShareSession(keyId, code);
      setEmojis(data.emojis);
      data.messages.sort((a, b) => a.date - b.date);
      setThread(data.thread);
      setMessages(data.messages);
    };

    fetchData().catch(console.error);
  }, []);
  if (messages && thread) {
    var userAvatar = thread.users.length > 0 ? (thread.users[0].avatar_url === "" ? null : thread.users[0].avatar_url) : null;
    var img = userAvatar ?? avatarIcon;
    var startDate = thread !== null ? new Date(thread.created_date) : null;
    var endDate = thread !== null ? new Date(thread.close_date) : null;
    var displayName = "Random_Lyfer";
    var user_name = "Random_Lyfer";

    if (thread.users !== undefined && thread.users !== null) {
      if (thread.users.length > 1) {
        if (thread.users[1].name !== "" && thread.users[1].name !== null && thread.users[1].name !== undefined) {
          displayName = thread.users[1].name;
        } else if (thread.users[1].display_name !== "" && thread.users[1].display_name !== null && thread.users[1].display_name !== undefined) {
          displayName = thread.users[1].display_name;
        }
      }
      if (thread.users.length > 0) {
        if (thread.users[0].name !== "" && thread.users[0].name !== null && thread.users[0].name !== undefined) {
          user_name = thread.users[0].name;
        } else if (thread.users[0].display_name !== "" && thread.users[0].display_name !== null && thread.users[0].display_name !== undefined) {
          user_name = thread.users[0].display_name;
        }
      }
    }
    return (
      <div className="w-100 sharedsession" style={{ backgroundColor: "#eee", minHeight: "100vh" }}>
        <div className="d-flex flex-column ms-1 me-1 ms-sm-2 me-sm-2 ms-md-3 me-md-3 ms-lg-5 me-lg-5">
          <div className="fs-3 mt-5">
            My Clients {">"} <span style={{ color: "#51a6aa" }}>Sessions & Notes: {user_name}</span>
          </div>
          <div className={mdMatches ? "d-flex flex-row pt-5" : "d-flex flex-column pt-5"}>
            <div
              className="sidebar d-block shadow ms-1 me-1 flex-shrink-0 sticky-top"
              style={{
                textAlign: mdMatches ? "left" : "center",
                maxWidth: mdMatches ? "35%" : "100%",
                backgroundColor: "#fff",
                minWidth: 33,
                minHeight: 35,
                overflow: "auto",
                height: mdMatches ? "100vh" : "auto",
              }}
            >
              <style>{`@media print {.sidebar{position: relative; height: auto !important; }}`}</style>
              <IconButton
                className="d-print-none"
                style={{ position: "absolute", right: 5, top: 5, color: "green", width: 23, height: 23 }}
                onClick={() => {
                  setOpenProfile(!openProfile);
                }}
              >
                &#9776;
              </IconButton>
              <Collapse
                in={openProfile}
                className="p-1 p-sm-3 p-md-4 p-lg-5"
                orientation={mdMatches ? "horizontal" : "vertical"}
                timeout="auto"
                unmountOnExit
              >
                <div className="d-grid gap-1">
                  <div style={{ color: "#51a6aa" }} className="mb-3">
                    <img className="rounded-circle" src={img} style={{ width: 80, height: 80 }} />
                    <div className="fs-5 lh-1 mt-2" style={{ color: "#51a6aa" }}>
                      {user_name}
                    </div>
                    <div>
                      <img src={"/logo.png"} className="rounded-circle d-inline-block align-top mt-1 me-1" style={{ width: 20, height: 20 }} />
                      <b style={{ fontSize: "10px" }}>Lyf Support Collab</b>
                    </div>
                  </div>
                  <div>
                    <span className="fw-bold me-2">ID:</span>
                    <span>{thread.users.length > 1 ? thread.users[1].user_id : ""}</span>
                  </div>
                  <div>
                    <span className="fw-bold me-2">Last Session:</span>
                    <span>
                      {String(startDate.getHours()).padStart(2, "0")}:{String(startDate.getMinutes()).padStart(2, "0")}-
                      {String(endDate.getHours()).padStart(2, "0")}:{String(endDate.getMinutes()).padStart(2, "0")}{" "}
                      {String(startDate.getDate()).padStart(2, "0")}/{String(startDate.getMonth() + 1).padStart(2, "0")}/{startDate.getFullYear()}
                    </span>
                  </div>
                  <div className="fs-6 pt-2" style={{ color: "#51a6aa" }}>
                    Lyf Support Session Notes:
                  </div>
                  <span className="fw-bold">Session Notes{thread.users.length > 1 ? " from " + displayName : ""}:</span>
                  <span>{thread.note}</span>
                </div>
              </Collapse>
            </div>

            <div className="flex-fill shadow p-4 ms-1 me-1" style={{ backgroundColor: "#fff" }}>
              <div className="text-center">
                <div className="cellHolderColor p-2">
                  <ul className="list-unstyled">
                    {messages &&
                      thread.status_id !== 2 &&
                      messages.map((message) => {
                        return <MessageCell key={message.message_id} thread={thread} message={message} maxWidth={xlMatches ? 80 : 95} />;
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="d-flex vh-100 flex-column justify-content-center align-items-center">
        <div>
          <Loader type="TailSpin" color="green" height={40} width={40} />
        </div>
      </div>
    );
  }
}

export default SharedSession;
