import React from "react";
import "../App.css";
import Linkify from "react-linkify";
import { Icon, IconButton } from "@mui/material";
import Dropdown from "react-bootstrap/Dropdown";
import { useAuth } from "../Contexts/authContext/index.jsx";
const pdfIcon = require("../Assets/pdf.png");
const videoIcon = require("../Assets/video.png");
const Networker = require("../Networker.js");
const IconButtonToggle = React.forwardRef(({ children, onClick }, ref) => (
  <IconButton
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    size="small"
  >
    {children}
  </IconButton>
));
IconButtonToggle.displayName = "IconButtonToggle";
export class AttachmentViewer extends React.Component {
  openInNewTab = (url) => {
    var win = window.open(url, "_blank");
    win.focus();
  };
  render() {
    if (this.props.attachments) {
      if (this.props.attachments.length === 0) {
        return <React.Fragment></React.Fragment>;
      }
      if (this.props.attachments.length === 1) {
        return (
          <div className="attachments">
            <AttachmentViewerCell
              attachment={this.props.attachments[0]}
              open={(url) => {
                this.openInNewTab(url);
              }}
              width={this.props.width ? this.props.width : "75%"}
              height={this.props.height ? this.props.height : "75%"}
            />
          </div>
        );
      } else if (this.props.attachments.length >= 2) {
        return (
          <div className={this.props.nowrap ? "d-flex attachments flex-nowrap" : "d-flex attachments flex-wrap"}>
            {this.props.attachments.map((row) => {
              return (
                <div>
                  <AttachmentViewerCell
                    attachment={row}
                    open={(url) => {
                      this.openInNewTab(url);
                    }}
                    width={this.props.width ? this.props.width : "100%"}
                    height={this.props.height ? this.props.height : "100%"}
                  />
                </div>
              );
            })}
          </div>
        );
      }
    }

    return <React.Fragment></React.Fragment>;
  }
}

class AttachmentViewerCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = { base64String: "" };
  }

  render() {
    return (
      <>
        {this.props.attachment.type_id === 1 && (
          <img
            onClick={() => this.props.open(this.props.attachment.url)}
            className="chatImage"
            src={this.props.attachment.url}
            alt=""
            style={{ width: this.props.width, height: this.props.height }}
          />
        )}
        {this.props.attachment.type_id === 2 && (
          <div onClick={() => this.props.open(this.props.attachment.url)}>
            <img className="chatImage" src={videoIcon} alt="" style={{ width: 30, height: 30, margin: "auto" }} />
          </div>
        )}
        {this.props.attachment.type_id === 3 && (
          <div>
            <img
              onClick={() => this.props.open(this.props.attachment.url)}
              className="chatImage"
              src={this.props.attachment.thumbnail_url}
              alt=""
              style={{ width: this.props.width, height: this.props.height }}
            />
            <div className="attachmentBanner">
              <p className="attachmentUrl">{this.props.attachment.url}</p>
              <p className="attachmentTitle">{this.props.attachment.name}</p>
            </div>
          </div>
        )}
        {this.props.attachment.type_id === 4 && (
          <div>
            <img
              onClick={() => this.props.open(this.props.attachment.url)}
              className="chatImage"
              src={this.props.attachment.thumbnail_url}
              alt=""
              style={{ width: this.props.width, height: this.props.height }}
            />
            <div className="attachmentBanner">
              <p className="attachmentUrl">{this.props.attachment.url}</p>
              <p className="attachmentTitle">{this.props.attachment.name}</p>
            </div>
          </div>
        )}
        {this.props.attachment.type_id === 5 && (
          <div onClick={() => this.props.open(this.props.attachment.url)}>
            <img className="chatImage" src={pdfIcon} alt="" style={{ width: 30, height: 30, margin: "auto" }} />
          </div>
        )}
      </>
    );
  }
}

export default function MessageCell(props) {
  const { user, emojis } = useAuth();
  const sendEmoji = (emoji) => {
    Networker.sendEmoji(props.message.message_id, emoji);
  };
  var decryptedText = props.message.text;
  var decryptedReplyText = props.message.reply_message ? props.message.reply_message.text : "";

  var displayName = "Random_Lyfer";

  if (props.message.creator.display_name !== "" && props.message.creator.display_name !== null) {
    displayName = "@" + props.message.creator.display_name;
  } else if (props.message.creator.name !== "" && props.message.creator.name !== null) {
    displayName = props.message.creator.name;
  }

  const date = new Date(props.message.date);
  var maxWidth = props.maxWidth === undefined || props.maxWidth === 0 ? 80 : props.maxWidth;
  return (
    <React.Fragment>
      <li className={props.message.creator.lyf_support_professional_flag === 1 ? "d-flex flex-row-reverse mb-4" : "d-flex mb-4"}>
        <div className="card" style={{ maxWidth: maxWidth + "%" }}>
          <div className="card-header d-flex flex-wrap justify-content-between p-3">
            <p className="fw-bold mb-0 me-3">{displayName}</p>
            <p className="text-muted small mb-0">
              <i className="far fa-clock"></i> {String(date.getDate()).padStart(2, "0")}/{String(date.getMonth() + 1).padStart(2, "0")}/
              {date.getFullYear()} {String(date.getHours()).padStart(2, "0")}:{String(date.getMinutes()).padStart(2, "0")}
            </p>
          </div>
          <div className={props.message.creator.lyf_support_professional_flag === 1 ? "card-body bg-lyfsupport" : "card-body"}>
            {props.message.reply_message && (
              <div className="card mb-2" style={{ backgroundColor: "#CBCBCC", borderLeft: "11px solid #67A4A9" }}>
                <div className="d-flex">
                  <div style={{ overflowX: "auto" }}>
                    <div className="ms-2 mt-1 me-1 text-start fw-bold" style={{ color: "#67A4A9" }}>
                      {props.message.reply_message.creator.display_name}
                    </div>
                    <div className="ms-2 mb-1 me-1 text-start" style={{ color: "#4A4A4A" }}>
                      {props.message.reply_message.attachments && props.message.reply_message.attachments.length > 0 && decryptedReplyText === ""
                        ? "Image"
                        : decryptedReplyText}
                    </div>
                  </div>
                  {props.message.reply_message.attachments && props.message.reply_message.attachments.length > 0 && (
                    <div className="ms-auto mt-auto mb-auto me-2 flex-grow-1" style={{ maxWidth: "55%" }}>
                      <AttachmentViewer attachments={props.message.reply_message.attachments} width="50px" height="50px" nowrap={false} />
                    </div>
                  )}
                </div>
              </div>
            )}
            <div
              className={
                props.message.attachments && props.message.attachments.length > 0
                  ? "text-center"
                  : props.message.reply_message
                  ? "text-start ms-3"
                  : "text-start"
              }
            >
              <AttachmentViewer attachments={props.message.attachments} nowrap={true} />
              {props.message.text && props.message.text !== "" && decryptedText !== "" && (
                <p className={props.message.creator.lyf_support_professional_flag === 1 ? "mb-0 text-white" : "mb-0"}>
                  <Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                      <a target="_blank" rel="noopener noreferrer" href={decoratedHref} key={key}>
                        {decoratedText}
                      </a>
                    )}
                  >
                    {decryptedText}
                  </Linkify>
                </p>
              )}
            </div>
          </div>
          {props.message.reactions && props.message.reactions.length > 0 && (
            <div
              className={
                "card-footer d-flex flex-wrap " +
                (props.message.creator.lyf_support_professional_flag === 1 ? "justify-content-start" : "justify-content-end")
              }
            >
              <img src={emojis.find((item) => item.name === props.message.reactions[0].reactionType).data_url} width="24" height="24" />
            </div>
          )}
        </div>
        {!props.message.is_system_message && props.thread.status_id === 1 && props.thread.support_user_id === user.user_id && (
          <div style={{ color: "#62a6a9" }} className="d-flex align-self-center">
            <Dropdown>
              <Dropdown.Toggle as={IconButtonToggle} id="reaction_button" className="pt-1">
                <Icon style={{ color: "#62a6a9" }}>sentiment_satisfied</Icon>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item style={{ backgroundColor: "white" }}>
                  <div className="d-flex flex-nowrap">
                    {emojis.map((emoji, i) => (
                      <IconButton size="small" onClick={() => sendEmoji(emoji)}>
                        <img src={emoji.data_url} width="24" height="24" />
                      </IconButton>
                    ))}
                    <IconButton size="small">
                      <Icon style={{ color: "#62a6a9" }}>add</Icon>
                    </IconButton>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <IconButton size="small" className="pt-1" onClick={() => props.selectReplyMessage(props.message)}>
              <Icon style={{ color: "#62a6a9" }}>reply</Icon>
            </IconButton>
          </div>
        )}
      </li>
    </React.Fragment>
  );
}
