import { signInWithCustomToken, setPersistence, browserLocalPersistence } from "firebase/auth";
import { auth } from "./firebase";
export const doSignInWithCustomToken = async (customToken) => {
  await setPersistence(auth, browserLocalPersistence);
  return signInWithCustomToken(auth, customToken);
};

export const doSignOut = () => {
  auth.signOut().then((v) => window.localStorage.clear());
};
