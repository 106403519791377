import React from "react";
import "../App.css";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import Avatar from "./Avatar";
// let timer = null;

class QueueCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = { time: 0 };
  }
  componentDidMount() {
    this.interval = setInterval(() => this.setState((prev) => ({ time: prev.time + 1 })), 1000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    let user = this.props.thread.users.length > 0 ? this.props.thread.users[0] : null;
    var sec = this.state.time;
    var min = Math.floor(sec / 60);
    sec = sec - 60 * min;

    var hr = Math.floor(min / 60);
    min = min - 60 * hr;

    var displayName = "@Random_Lyfer";

    if (this.props.thread.users !== undefined && this.props.thread.users !== null) {
      if (this.props.thread.users.length > 0) {
        if (user.display_name !== "" && user.display_name !== null && user.display_name !== undefined) {
          displayName = "@" + user.display_name;
        } else if (user.name !== "" && user.name !== null && user.name !== undefined) {
          displayName = user.name;
        }
      }
    }

    var lbl = "Waiting ";
    if (hr > 0) {
      lbl += hr + "h ";
    }
    if (min > 0) {
      lbl += min + "m ";
    }
    lbl += sec + "s";
    var cls = this.props.selected ? "threadCell selected" : "threadCell";

    return (
      <ListItemButton selected={this.props.selected ? true : false} alignItems="flex-start" onClick={this.props.onClick}>
        {this.props.thread.users && (
          <ListItemAvatar>
            <Avatar user={user} />
          </ListItemAvatar>
        )}
        <ListItemText primary={displayName} secondary={<React.Fragment>{lbl}</React.Fragment>} />
      </ListItemButton>
    );
  }
}

export default QueueCell;
