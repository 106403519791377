import React from "react";
import ReactDOM from "react-dom";
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import App from "./App";
import SharedSession from "./Components/SharedSession";
import { isSupported } from "firebase/messaging";
import { AuthProvider } from "./Contexts/authContext";
//import * as serviceWorker from './serviceWorker';
import { StyledEngineProvider } from "@mui/material/styles";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/session/:keyId/:code",
    element: <SharedSession />,
  },
]);

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <StyledEngineProvider injectFirst>
        <RouterProvider router={router} />
      </StyledEngineProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change.
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
