import React from "react";
import "../App.css";
import Dropzone from "react-dropzone";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Resizer from "react-image-file-resizer";
import { v4 as uuidv4 } from "uuid";
import { Card } from "react-bootstrap";
import { AuthContext } from "../Contexts/authContext";

const MySwal = withReactContent(Swal);
const Networker = require("../Networker.js");
const pdfIcon = require("../Assets/pdf.png");
const videoIcon = require("../Assets/video.png");

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1280,
      1280,
      "JPEG",
      80,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

class AttachmentCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = { base64String: "" };
  }

  handleClick(e) {
    e.preventDefault();
    this.props.onDelete(this.props.index);
  }

  async componentDidMount() {
    var body = await toBase64(this.props.file);
    this.setState((state) => ({
      base64String: body,
    }));
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.file !== this.props.file) {
      var body = await toBase64(this.props.file);
      this.setState((state) => ({
        base64String: body,
      }));
    }
  }

  render() {
    if (this.props.file.type === "image/jpeg" || this.props.file.type === "image/png" || this.props.file.type === "image/gif") {
      return (
        <div className="chatBoxFileScroller me-1">
          <img src={this.state.base64String} alt="att" />
          <a onClick={(e) => this.handleClick(e)} href="#att">
            X
          </a>
        </div>
      );
    } else if (this.props.file.type === "video/mp4" || this.props.file.type === "video/mp3") {
      return (
        <div className="chatBoxFileScroller me-1">
          <img src={videoIcon} alt="att" />
          <a onClick={(e) => this.handleClick(e)} href="#att">
            X
          </a>
        </div>
      );
    } else if (this.props.file.type === "application/pdf") {
      return (
        <div className="chatBoxFileScroller me-1">
          <img src={pdfIcon} alt="att" />
          <a onClick={(e) => this.handleClick(e)} href="#att">
            X
          </a>
        </div>
      );
    }

    return <React.Fragment></React.Fragment>;
  }
}

class ChatBox extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      text: "",
      files: [],
    };
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    if (this.messageInput) {
      this.messageInput.focus();
    }
  }

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleDelete(index) {
    var arr = Array.from(this.state.files);
    arr.splice(index, 1);

    this.setState((state) => ({
      files: arr,
    }));
  }

  handleInput(e) {
    var oldTxt = this.state.text;
    var txt = e.target.value;
    this.setState((state) => ({
      text: txt,
    }));

    if (oldTxt === "" && txt !== "") {
      this.props.typingChanged(true);
    } else if (oldTxt !== "" && txt === "") {
      this.props.typingChanged(false);
    }
  }

  onDrop = (acceptedFiles) => {
    var newArr = this.state.files.concat(acceptedFiles);
    this.setState((state) => ({
      files: newArr,
    }));
  };

  onKeyPress(e) {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      this.send();
    }
  }

  async send() {
    if (this.state.files.length == 0 && (this.state.text === null || this.state.text.match(/^ *$/) !== null)) {
      alert("Please enter a message.");
      return;
    }
    // let the user know the message is going out
    this.setState((state) => ({
      loading: true,
    }));

    var attachments = [];
    var body = "";

    for (var i = 0; i < this.state.files.length; i++) {
      if (this.state.files[i].type === "image/jpeg" || this.state.files[i].type === "image/png" || this.state.files[i].type === "image/gif") {
        body = await resizeFile(this.state.files[i]);
        attachments.push(body);
      } else {
        body = await toBase64(this.state.files[i]);
        attachments.push(body);
      }
    }

    var message_id = uuidv4();
    var plainText = this.state.text;

    // reset to blank
    this.setState((state) => ({
      loading: false,
      text: "",
      files: [],
    }));

    this.messageInput.focus();
    await Networker.setTyping(this.props.thread.thread_id, false);
    var clientUserId = this.props.thread.users && this.props.thread.users.length > 0 ? this.props.thread.users[0].user_id : 0;
    await Networker.sendMessage(
      message_id,
      plainText,
      this.context.user.user_id,
      clientUserId,
      this.context.user.display_name,
      attachments,
      this.props.thread.thread_id,
      this.props.replyMessageSelected,
      this.props.plainReplyText
    );
    if (this.props.replyMessageSelected) {
      this.props.deleteReplyMessage();
    }
  }

  showEditNote() {
    MySwal.fire({
      title: "Edit notes",
      input: "textarea",
      inputValue: this.props.thread.note,
      showLoaderOnConfirm: true,
      inputAttributes: { maxlength: 2048 },
      showCancelButton: true,
      confirmButtonText: "Save",
      inputValidator: (value) => {
        if (!value) {
          return "Note is required.";
        }
      },
      preConfirm: async (value) => {
        if (value) {
          await Networker.addNote(this.props.thread, value).then(() => {
            this.props.thread.note = value;
            this.context.updateThread(this.props.thread);
          });
        }
      },
    });
  }

  render() {
    var retired = this.props.thread.status_id === 3;
    var ismine = this.props.thread.support_user_id === this.context.user.user_id;
    return (
      <div className="chatBox flex-grow-1 p-1 pt-0 pt-lg-0 p-lg-2">
        {retired === true && (
          <p className="chatBoxNote">
            <strong>
              Note:&nbsp; &nbsp;
              <a href="#noredirect" onClick={() => this.showEditNote()}>
                Edit notes
              </a>
              &nbsp; &nbsp;
            </strong>
            {this.props.thread.note}
          </p>
        )}
        {retired === false && ismine === true && (
          <Card className="h-100" style={{ borderRadius: "15px" }}>
            <Card.Body className="p-1 d-flex flex-column">
              <div className="form-group flex-grow-1 m-2">
                <textarea
                  className="form-control"
                  value={this.state.text}
                  ref={(input) => {
                    this.messageInput = input;
                  }}
                  onChange={(e) => this.handleInput(e)}
                  onKeyDown={(e) => this.onKeyPress(e)}
                  autoComplete="off"
                  name="draft"
                  id="draft"
                  placeholder="Type a message..."
                  disabled={this.state.loading || (this.props.thread.expired_flag === 1 && retired) || this.props.thread.status_id === 2}
                />
              </div>

              <div className="d-flex align-items-center">
                {this.state.loading === false &&
                  this.state.files &&
                  this.state.files.map((file, index) => {
                    return <AttachmentCell file={file} index={index} onDelete={this.handleDelete} />;
                  })}

                <div className="flex-grow-1 me-2">
                  <div className="chatBoxFiles">
                    <Dropzone
                      onDrop={(acceptedFiles) => this.onDrop(acceptedFiles)}
                      accept={["image/*", "video/mp4", "video/mp3", "application/pdf"]}
                      maxFiles={8}
                      multiple={true}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <p>Drop files here</p>
                        </div>
                      )}
                    </Dropzone>
                  </div>
                </div>
                <button
                  onClick={() => this.send()}
                  disabled={this.state.loading || (this.props.thread.expired_flag === 1 && retired) || this.props.thread.status_id === 2}
                >
                  Send
                </button>
              </div>
            </Card.Body>
          </Card>
        )}
        {retired === false && ismine === false && <p></p>}
      </div>
    );
  }
}

export default ChatBox;
