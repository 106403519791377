import { signOut, signInWithCustomToken, setPersistence, browserLocalPersistence } from "firebase/auth";
import { collection, doc, setDoc, getDocs, query, where, updateDoc, addDoc, serverTimestamp } from "firebase/firestore";
import { getMessaging, getToken, isSupported, onMessage } from "firebase/messaging";
import { v4 as uuidv4 } from "uuid";
import { auth, firebaseVapidKey, db } from "./firebase/firebase";
var Base64 = require("js-base64").Base64;
const getIdToken = async () => {
  if (auth.currentUser) {
    return await auth.currentUser.getIdToken();
  }
  return "";
};
export const getMessagesForThread = async (thread, page, abortSignal = null) => {
  if (thread != null) {
    return await fetch(process.env.REACT_APP_API_DOMAIN + "lyfsupport/messages/" + thread.thread_id + "/?p=" + page, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (await getIdToken()),
        "App-Version": "platform-web-1.0",
      },
      signal: abortSignal,
    })
      /*.then(res => res.json()).then(
     async (result) => {
     return result;
     },
     (error) => {
     console.log(error);
     return null;
     }
     );*/
      .then((res) => res.text())
      .then(
        (result) => {
          try {
            return JSON.parse(result);
          } catch {
            console.log(result);
            return [];
          }
        },
        (error) => {
          console.log(error);
          return null;
        }
      );
  } else {
    console.log("no thread passed");
    return null;
  }
};

export const logOut = async (auth) => {
  await signOut(auth);
  window.localStorage.clear();
};
export const getCustomToken = async (email, password) => {
  const result = await fetch(process.env.REACT_APP_API_DOMAIN + "psych/customToken", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "App-Version": "platform-web-1.0",
    },
    body: JSON.stringify({
      email: email,
      password: password,
    }),
  })
    .then((res) => res.json())
    .then(
      (result) => {
        if (result != null) {
          return result;
        } else {
          return null;
        }
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  return result;
};
/*export const startSessionForUser = async (email, password) => {
  const result = await fetch(process.env.REACT_APP_API_DOMAIN + "psych/customToken", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "App-Version": "platform-web-1.0",
    },
    body: JSON.stringify({
      email: email,
      password: password,
    }),
  })
    .then((res) => res.json())
    .then(
      (result) => {
        if (result.user != null && result.token != null) {
          return result;
        } else {
          return null;
        }
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
  if (result != null) {
    const userCredit = await signInWithCustomToken(auth, result.token);
    if (isSupported()) {
      const messaging = getMessaging();
      var deviceToken = window.localStorage.getItem("deviceToken");
      if (deviceToken === "" || deviceToken === null || deviceToken === undefined) {
        getToken(messaging, { vapidKey: firebaseVapidKey })
          .then((currentToken) => {
            if (currentToken) {
              // Send the token to your server and update the UI if necessary
              console.log(currentToken);
              saveDevice(currentToken);
            } else {
              // Show permission request UI
              console.log("No registration token available. Request permission to generate one.");
            }
          })
          .catch((err) => {
            console.log("An error occurred while retrieving token. ", err);
          });
      }
      onMessage(messaging, (payload) => {
        console.log("Message received. ", payload);
      });
    }
    return result.user;
  }

  return null;
};*/

export const saveDevice = async (token) => {
  var deviceToken = window.localStorage.getItem("deviceToken");
  if (deviceToken === "" || deviceToken === null || deviceToken === undefined) {
    deviceToken = uuidv4();
  }

  const varBody = {
    device_token: deviceToken,
    firebase_token: token,
    method_id: 1,
    os_version: "Browser",
    app_version: "1.1",
    model: "Chrome",
    type_id: 3,
    app_id: 2,
    prefix: "lyfsupport",
  };

  return await fetch(process.env.REACT_APP_API_DOMAIN + "me/devices", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + (await getIdToken()),
      "App-Version": "platform-web-1.0",
    },
    body: JSON.stringify(varBody),
  })
    .then((res) => res.json())
    .then(
      async (result) => {
        console.log("claim made");
        await window.localStorage.setItem("deviceToken", deviceToken);
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const takeSession = async (thread_id, user_id) => {
  console.log("TAKEOVER - UPDATING SESSION IN FB");
  await updateDoc(doc(db, "sessions", thread_id), {
    supportUserID: user_id,
  });

  console.log("TAKEOVER - FINISHED UPDATING SESSION IN FB");

  console.log("TAKEOVER - UPDATING SESSION IN API");

  return await fetch(process.env.REACT_APP_API_DOMAIN + "lyfsupport/queue/" + thread_id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + (await getIdToken()),
      "App-Version": "platform-web-1.0",
    },
  })
    .then((res) => res.json())
    .then(
      async (result) => {
        console.log("TAKEOVER - FINISHED UPDATING SESSION IN API - SUCCESS!");
        return result;
      },
      (error) => {
        console.log("TAKEOVER - FINISHED UPDATING SESSION IN API - ERROR");
        console.log(error);
        return null;
      }
    );
};

export const sendMessage = async (
  message_id,
  plaintext,
  user_id,
  clientUserId,
  display_name,
  attachments,
  thread_id,
  replyMessage,
  plainReplyText
) => {
  var newAttachments = [];
  if (attachments.length > 0) {
    // first, upload / create server message (uploading can take time)
    newAttachments = await fetch(process.env.REACT_APP_API_DOMAIN + "message-attachments/" + message_id, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (await getIdToken()),
        "App-Version": "platform-web-1.0",
      },
      body: '{ "attachments": ' + JSON.stringify(attachments) + "}",
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.message) {
            console.log("error in attachment:" + result.message);
            return [];
          }
          return result;
        },
        (error) => {
          console.log(error);
          return [];
        }
      );

    newAttachments.forEach((element) => {
      if (element.dateCreated) element.dateCreated = new Date(element.dateCreated);
    });
  }
  // now, let's save all this to Firebase
  try {
    var sID_2 = "";
    var expiration_date_2 = null;

    // lookup the session ID from the thread ID
    const q = query(collection(db, "sessions"), where("threadID", "==", thread_id));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((document) => {
      sID_2 = document.id;
      expiration_date_2 = document.data().expirationDate;
    });

    console.log("EXPIRATION:");
    console.log(expiration_date_2);

    // determine if we should start the session offically (if there are messages from client, then start it)
    if (expiration_date_2 === null || expiration_date_2 === undefined) {
      const mq = query(collection(db, "messages"), where("sessionID", "==", sID_2), where("author.userID", "!=", user_id));
      const mqSnapshot = await getDocs(mq);

      if (mqSnapshot.size > 0) {
        // use the server to set the start date
        const sessRef = doc(db, "sessions", sID_2);
        await setDoc(
          sessRef,
          {
            startDate: serverTimestamp(),
          },
          { merge: true }
        );
      }
    }

    // insert the new message
    await setDoc(
      doc(db, "messages", message_id),
      {
        messageID: message_id,
        sessionID: sID_2,
        text: plaintext,
        author: {
          userID: user_id,
          displayName: display_name != null && display_name != undefined ? display_name : "Lyf Psych",
          avatarUrl: "",
        },
        clientUserId: clientUserId,
        replayMessage: replyMessage
          ? {
              messageID: replyMessage.message_id,
              text: plainReplyText,
              author: {
                userID: replyMessage.creator.user_id,
                displayName: replyMessage.creator.display_name,
                avatarUrl: "",
              },
              attachments: replyMessage.attachments,
            }
          : null,
        attachments: newAttachments,
        sentDate: serverTimestamp(),
        type: 1,
      },
      { merge: true }
    );

    await updateDoc(doc(db, "sessions", sID_2), {
      latestMessage: {
        messageID: message_id,
        text: plaintext,
        author: {
          userID: user_id,
          displayName: display_name != null && display_name != undefined ? display_name : "Lyf Psych",
          avatarUrl: "",
        },
        attachments: newAttachments,
        sentDate: serverTimestamp(),
        type: 1,
      },
    });
  } catch (error) {
    console.log("Document written error: ", error.message);
  }
};

export const getThreads = async () => {
  var threads = [];

  return await fetch(process.env.REACT_APP_API_DOMAIN + "me/threads?p=1&t=3", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + (await getIdToken()),
      "App-Version": "platform-web-1.0",
    },
  })
    .then((res) => res.json())
    .then(
      async (result) => {
        threads = result;
        return threads;
      },
      (error) => {
        console.log(error);
        return threads;
      }
    );
};

export const getHistory = async (thread) => {
  var threads = [];

  return await fetch(process.env.REACT_APP_API_DOMAIN + "thread/" + thread.thread_id + "/history/?p=0", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + (await getIdToken()),
      "App-Version": "platform-web-1.0",
    },
  })
    .then((res) => res.json())
    .then(
      async (result) => {
        threads = result;
        return threads;
      },
      (error) => {
        console.log(error);
        return threads;
      }
    );
  /*.then(res => res.text()).then((result) => {
   console.log(result);
   return null;
   }, (error) => {
   console.log(error);
   return null;
   });*/
};

export const getPrevious = async (pageNumber, search = "") => {
  var threads = [];

  return await fetch(process.env.REACT_APP_API_DOMAIN + "lyfsupport/closed/?p=" + pageNumber + "&s=" + search, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + (await getIdToken()),
      "App-Version": "platform-web-1.0",
    },
  })
    .then((res) => res.json())
    .then(
      async (result) => {
        threads = result;
        return threads;
      },
      (error) => {
        console.log(error);
        return [];
      }
    );
  /*.then(res => res.text()).then((result) => {
   console.log(result);
   return null;
   }, (error) => {
   console.log(error);
   return null;
   });*/
};
export const getArchiveThreads = async (pageNumber, search = "") => {
  var threads = [];

  return await fetch(process.env.REACT_APP_API_DOMAIN + "lyfsupport/closed/?p=" + pageNumber + "&s=" + search + "&t=archive", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + (await getIdToken()),
      "App-Version": "platform-web-1.0",
    },
  })
    .then((res) => res.json())
    .then(
      async (result) => {
        threads = result;
        return threads;
      },
      (error) => {
        console.log(error);
        return [];
      }
    );
  /*.then(res => res.text()).then((result) => {
   console.log(result);
   return null;
   }, (error) => {
   console.log(error);
   return null;
   });*/
};
export const getEmojis = async () => {
  return await fetch(process.env.REACT_APP_API_DOMAIN + "emojies", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + (await getIdToken()),
      "App-Version": "platform-web-1.0",
    },
  })
    .then((res) => res.json())
    .then(
      async (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return [];
      }
    );
};

export const getPartnerships = async () => {
  return await fetch(process.env.REACT_APP_API_DOMAIN + "lyfsupport/partnerships", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + (await getIdToken()),
      "App-Version": "platform-web-1.0",
    },
  })
    .then((res) => res.json())
    .then(
      async (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return [];
      }
    );
};

export const sendEmoji = async (message_id, emoji) => {
  const q = query(collection(db, "messages"), where("messageID", "==", message_id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((document) => {
    updateDoc(document.ref, {
      reaction: { reactionId: emoji.id, reactionType: emoji.name },
    });
  });
};

export const getStaff = async () => {
  var users = [];

  return await fetch(process.env.REACT_APP_API_DOMAIN + "lyfsupport/staff", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + (await getIdToken()),
      "App-Version": "platform-web-1.0",
    },
  })
    .then((res) => res.json())
    .then(
      async (result) => {
        users = result;
        return users;
      },
      (error) => {
        console.log(error);
        return users;
      }
    );
};

export const goOnline = async (user_id, display_name) => {
  var isFound = false;
  const q = query(collection(db, "psychs"), where("userID", "==", user_id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((document) => {
    isFound = true;

    updateDoc(document.ref, {
      status: 1,
    });
  });

  if (!isFound) {
    try {
      addDoc(collection(db, "psychs"), {
        userID: user_id,
        displayName: display_name != null && display_name != undefined ? display_name : "Lyf Psych",
        status: 1,
        onlineDate: null,
      });
    } catch (error) {
      console.log("Document written error: ", error.message);
    }
  }

  return await fetch(process.env.REACT_APP_API_DOMAIN + "lyfsupport/online", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + (await getIdToken()),
      "App-Version": "platform-web-1.0",
    },
  })
    .then((res) => res.json())
    .then(
      async (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const goOffline = async (user_id, display_name) => {
  var isFound = false;
  const q = query(collection(db, "psychs"), where("userID", "==", user_id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((document) => {
    isFound = true;
    updateDoc(document.ref, {
      status: 2,
    });
  });
  if (!isFound) {
    try {
      addDoc(collection(db, "psychs"), {
        userID: user_id,
        displayName: display_name != null && display_name != undefined ? display_name : "Lyf Psych",
        status: 2,
        onlineDate: null,
      });
    } catch (error) {
      console.log("Document written error: ", error.message);
    }
  }
  return await fetch(process.env.REACT_APP_API_DOMAIN + "lyfsupport/online", {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + (await getIdToken()),
      "App-Version": "platform-web-1.0",
    },
  })
    .then((res) => res.json())
    .then(
      async (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const setStatus = async (user_id, display_name, isOnline, statusDate) => {
  var isFound = false;
  const q = query(collection(db, "psychs"), where("userID", "==", user_id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((document) => {
    isFound = true;

    if (isOnline) {
      updateDoc(document.ref, {
        onlineDate: new Date(statusDate),
      });
    } else {
      updateDoc(document.ref, {
        offlineDate: new Date(statusDate),
      });
    }
  });

  if (!isFound) {
    try {
      if (isOnline) {
        addDoc(collection(db, "psychs"), {
          userID: user_id,
          displayName: display_name != null && display_name != undefined ? display_name : "Lyf Psych",
          status: 2,
          onlineDate: new Date(statusDate),
        });
      } else {
        addDoc(collection(db, "psychs"), {
          userID: user_id,
          displayName: display_name != null && display_name != undefined ? display_name : "Lyf Psych",
          status: 2,
          offlineDate: new Date(statusDate),
        });
      }
    } catch (error) {
      console.log("Document written error: ", error.message);
    }
  }

  const varBody = {
    end_date: statusDate,
    is_online: isOnline ? 1 : 0,
  };

  return await fetch(process.env.REACT_APP_API_DOMAIN + "lyfsupport/online", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + (await getIdToken()),
      "App-Version": "platform-web-1.0",
    },
    body: JSON.stringify(varBody),
  })
    .then((res) => res.json())
    .then(
      async (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const setTyping = async (thread_id, is_typing) => {
  const q = query(collection(db, "sessions"), where("threadID", "==", thread_id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((document) => {
    updateDoc(document.ref, {
      supportUserIsTyping: is_typing,
    });
  });
};

export const claimChat = async (thread, user_id) => {
  const q = query(collection(db, "sessions"), where("threadID", "==", thread.thread_id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((document) => {
    updateDoc(document.ref, {
      supportUserID: user_id,
      status: 1,
    });
  });

  return await fetch(process.env.REACT_APP_API_DOMAIN + "lyfsupport/queue/" + thread.thread_id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + (await getIdToken()),
      "App-Version": "platform-web-1.0",
    },
  }).then((res) => {
    if (res.status !== 200) {
      querySnapshot.forEach((document) => {
        updateDoc(document.ref, {
          supportUserID: null,
          status: 2,
        });
      });
    }
    return res.status === 200 ? true : false;
  });
};

export const closeChat = async (thread) => {
  const q = query(collection(db, "sessions"), where("threadID", "==", thread.thread_id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((document) => {
    updateDoc(document.ref, {
      activeDate: serverTimestamp(), //new Date(now.getTime() + (5 * 60000)),
      status: 3,
      supportUserClosed: true,
      closedDate: serverTimestamp(),
    });
  });
};

export const addNote = async (thread, note) => {
  const q = query(collection(db, "sessions"), where("threadID", "==", thread.thread_id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((document) => {
    return updateDoc(document.ref, {
      note: note,
    });
  });
};
export const reportUser = async (user_id, note) => {
  const varBody = {
    text: note,
  };

  return await fetch(process.env.REACT_APP_API_DOMAIN + "user/" + user_id + "/report", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + (await getIdToken()),
      "App-Version": "platform-web-1.0",
    },
    body: JSON.stringify(varBody),
  })
    .then((res) => res.json())
    .then(
      async (result) => {
        console.log("report filed");
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const blockUser = async (user_id) => {
  return await fetch(process.env.REACT_APP_API_DOMAIN + "user/" + user_id + "/block", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + (await getIdToken()),
      "App-Version": "platform-web-1.0",
    },
  })
    .then((res) => res.json())
    .then(
      async (result) => {
        console.log("user blocked");
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const shareSession = async (email, thread_id) => {
  const varBody = {
    email: email,
    thread_id: thread_id,
  };

  return await fetch(process.env.REACT_APP_API_DOMAIN + "lyfsupport/share_session", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + (await getIdToken()),
      "App-Version": "platform-web-1.0",
    },
    body: JSON.stringify(varBody),
  })
    .then((res) => res.json())
    .then(
      async (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const getShareSession = async (keyId, code) => {
  const varBody = {
    key_id: keyId,
    code: code,
  };

  return await fetch(process.env.REACT_APP_API_DOMAIN + "lyfsupport/share_session", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + (await getIdToken()),
      "App-Version": "platform-web-1.0",
    },
    body: JSON.stringify(varBody),
  })
    .then((res) => res.json())
    .then(
      async (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const sendWellneshEmail = async (thread_id) => {
  return await fetch(process.env.REACT_APP_API_DOMAIN + "lyfsupport/wellness_check/" + thread_id, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + (await getIdToken()),
      "App-Version": "platform-web-1.0",
    },
  }).then((res) => (res.status === 200 ? true : false));
};
