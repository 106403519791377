import React from "react";
import "../App.css";
import { doSignOut } from "../firebase/auth";
import { AuthContext } from "../Contexts/authContext";
import { goOffline } from "../Networker";
const avatarIcon = require("../Assets/user.png");

class CurrentUser extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);

    this.handleOnAction = this.handleOnAction.bind(this);
    this.handleOnActive = this.handleOnActive.bind(this);
    this.handleOnIdle = this.handleOnIdle.bind(this);
  }

  async logout(e) {
    e.preventDefault();
    if (this.context.user) {
      await goOffline(this.context.user.user_id, this.context.user.display_name);
    }
    doSignOut();
  }

  componentDidMount() {}

  componentDidUpdate() {}

  handleOnAction(event) {}

  handleOnActive(event) {}

  handleOnIdle(event) {}

  render() {
    var userAvatar = this.context.user.avatar_url === "" ? null : this.context.user.avatar_url;
    var img = userAvatar ?? avatarIcon;
    const styles = {
      backgroundImage: "url(" + img + ")",
    };

    return (
      <div className="currentUser">
        <div className="avatarWrapper">
          <div className="avatar" style={styles}></div>
          <div className={this.props.online ? "online" : "offline"} />
        </div>
        <div className="info">
          <h1>{this.context.user.name}</h1>
          <p>
            <a onClick={(e) => this.logout(e)} href="/">
              Log Out
            </a>
          </p>
        </div>
      </div>
    );
  }
}

export default CurrentUser;
