import React from "react";
import ReactDOMServer from "react-dom/server";
import "../App.css";
import Icon from "@mui/material/Icon";
import Dropdown from "react-bootstrap/Dropdown";
import { IconButton } from "@mui/material";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Avatar from "./Avatar";
const MySwal = withReactContent(Swal);
const Networker = require("../Networker.js");

class PreviousCell extends React.Component {
  static displayName = "(Not Set)";
  showForwardTranscript() {
    let canShare = this.props.thread.can_share === 0 ? false : true;
    const date = new Date(this.props.thread.close_date);
    const dateShow =
      String(date.getDate()).padStart(2, "0") +
      "/" +
      String(date.getMonth() + 1).padStart(2, "0") +
      "/" +
      date.getFullYear() +
      " " +
      String(date.getHours()).padStart(2, "0") +
      ":" +
      String(date.getMinutes()).padStart(2, "0");
    if (canShare) {
      var emailPartnership =
        this.props.thread.users.length > 0 && this.props.thread.users[0].partnership ? this.props.thread.users[0].partnership.partnership_email : "";
      MySwal.fire({
        title: "Share transcript of " + this.displayName + " from " + dateShow,
        input: "textarea",
        showLoaderOnConfirm: true,
        inputAttributes: { maxlength: 2048, placeholder: "Type email address to share transcript with" },
        inputValue: emailPartnership,
        showCancelButton: true,
        confirmButtonText: "Share",
        inputValidator: (value) => {
          if (!value) {
            return "Email address is required.";
          }
        },
        preConfirm: async (value) => {
          if (value) {
            var results = await Networker.shareSession(value, this.props.thread.thread_id);
          }
        },
      });
    } else {
      MySwal.fire({
        title: "This user " + this.displayName + " has not given consent to share their session transcript from " + dateShow,
        confirmButtonText: "Close",
      });
    }
  }
  sendWellnessEmail() {
    MySwal.fire({
      title: "Are you sure to send wellness email to " + this.displayName,
      showLoaderOnConfirm: true,
      showCancelButton: true,
      confirmButtonText: "Send",
      preConfirm: async () => {
        var result = await Networker.sendWellneshEmail(this.props.thread.thread_id);
        if (result) {
          this.props.thread.wellness_check = 1;
          this.props.setPreviousThreads([...this.props.threads]);
        }
      },
    });
  }

  render() {
    let user = this.props.thread.users.length > 0 ? this.props.thread.users[0] : null;

    if (this.props.thread.users !== undefined && this.props.thread.users !== null) {
      if (this.props.thread.users.length > 0) {
        // Sometimes, we cannot make sure which one of users is the patient.
        // To make sure so, we should filter the list of thread users to see which one
        // has the lyf_support_professional_flag equal to 0

        user = this.props.thread.users.filter(function (item) {
          return item.lyf_support_professional_flag === 0;
        })[0];
        if (!user) user = this.props.thread.users[0];

        if (user.display_name !== "" && user.display_name !== null && user.display_name !== undefined) {
          this.displayName = "@" + user.display_name;
        } else if (user.name !== "" && user.name !== null && user.name !== undefined) {
          this.displayName = user.name;
        }
      } else {
        this.displayName = "@Random_Lyfer";
      }
    } else {
      this.displayName = "@Random_Lyfer";
    }
    const date = new Date(this.props.thread.close_date);
    if (this.props.selected) {
      return (
        <div className={"threadCell selected"} onClick={this.props.onClick}>
          {this.props.thread.users && (
            <div>
              <div className="avatar">
                <Avatar user={user} />
              </div>
              <p className="username d-flex">
                <div style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{this.displayName}</div>

                <div>
                  {this.props.thread.wellness_check === 1 && <Icon style={{ color: "#4ac4a2", height: "17px", marginLeft: "5px" }}>email</Icon>}
                </div>
              </p>
            </div>
          )}
          <p className="message">
            {String(date.getDate()).padStart(2, "0")}/{String(date.getMonth() + 1).padStart(2, "0")}/{date.getFullYear()}{" "}
            {String(date.getHours()).padStart(2, "0")}:{String(date.getMinutes()).padStart(2, "0")}
          </p>
          <Dropdown className="menu">
            <Dropdown.Toggle as={Icon} style={{ color: "#4ac4a2" }}>
              more_horiz
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {!this.props.thread.wellness_check && (
                <Dropdown.Item onClick={() => this.sendWellnessEmail()}>
                  <IconButton size="small" className="pt-1">
                    <Icon>email</Icon>
                  </IconButton>
                  Send Wellness Email
                </Dropdown.Item>
              )}
              <Dropdown.Item onClick={() => this.showForwardTranscript()}>
                <IconButton size="small" className="pt-1">
                  <Icon>forward</Icon>
                </IconButton>
                Forward Transcript
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      );
    } else {
      return (
        <div className={"threadCell"} onClick={this.props.onClick}>
          {this.props.thread.users && (
            <div>
              <div className="avatar">
                <Avatar user={user} />
              </div>

              <p className="username d-flex">
                <div style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{this.displayName}</div>

                <div>
                  {this.props.thread.wellness_check === 1 && <Icon style={{ color: "#4ac4a2", height: "17px", marginLeft: "5px" }}>email</Icon>}
                </div>
              </p>
            </div>
          )}
          <p className="message">
            {String(date.getDate()).padStart(2, "0")}/{String(date.getMonth() + 1).padStart(2, "0")}/{date.getFullYear()}{" "}
            {String(date.getHours()).padStart(2, "0")}:{String(date.getMinutes()).padStart(2, "0")}
          </p>
          <Dropdown className="menu">
            <Dropdown.Toggle as={Icon} style={{ color: "#4ac4a2" }}>
              more_horiz
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {!this.props.thread.wellness_check && (
                <Dropdown.Item onClick={() => this.sendWellnessEmail()}>
                  <IconButton size="small" className="pt-1">
                    <Icon>email</Icon>
                  </IconButton>
                  Send Wellness Email
                </Dropdown.Item>
              )}
              <Dropdown.Item onClick={() => this.showForwardTranscript()}>
                <IconButton size="small" className="pt-1">
                  <Icon>forward</Icon>
                </IconButton>
                Forward Transcript
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      );
    }
  }
}

export default PreviousCell;
