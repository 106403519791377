import { onAuthStateChanged } from "firebase/auth";
import React, { useContext, useEffect, useState, useRef, useCallback } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { getMessaging, getToken, isSupported, onMessage } from "firebase/messaging";
import { auth, db, firebaseVapidKey } from "../../firebase/firebase.js";
import { doSignInWithCustomToken, doSignOut } from "../../firebase/auth.js";
import DatePicker from "react-widgets/DatePicker";
const MySwal = withReactContent(Swal);
export const AuthContext = React.createContext();
export function useAuth() {
  return useContext(AuthContext);
}
export function AuthProvider({ children }) {
  const unsubscribeQueueThreads = useRef();
  const unsubscribeActiveThreads = useRef();
  const unsubscribeOtherThreads = useRef();
  const [currentUser, setCurrentUser] = useState(null);

  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [logingLoading, setLogingLoading] = useState(true);
  const [queueLoading, setQueueLoading] = useState(true);
  const didUserMountRef = useRef(false);
  const [user, setUser] = useState(JSON.parse(window.localStorage.getItem("user")));
  useEffect(() => {
    if (didUserMountRef.current) {
      window.localStorage.setItem("user", JSON.stringify(user));
      if (user != null && !auth.currentUser) {
        doSignInWithCustomToken(user.token);
      }
    }
    didUserMountRef.current = true;
  }, [user]);
  const [online, setOnline] = useState(JSON.parse(window.localStorage.getItem("online")) ?? false);
  const [tabID, setTabID] = useState(window.localStorage.getItem("tabID") ?? "session");
  useEffect(() => {
    window.localStorage.setItem("tabID", tabID);
  }, [tabID]);

  const [showSidebar, setShowSidebar] = useState(false);
  const fuckYouReactSucksDonkeyBalls = useRef(new Date());
  useEffect(() => {
    window.localStorage.setItem("online", online);
  }, [online]);

  // const [dateValue, setDateValue] = useState(0);
  const [dateOptionValue, setDateOptionValue] = useState(JSON.parse(window.localStorage.getItem("dateOptionValue")) ?? 1);
  useEffect(() => {
    window.localStorage.setItem("dateOptionValue", dateOptionValue);
  }, [dateOptionValue]);

  const Networker = require("./../../Networker.js");
  const [selectedThread, setSelectedThread] = useState(JSON.parse(window.localStorage.getItem("selectedThread")));
  /*useEffect(() => {
   window.localStorage.setItem("selectedThread", JSON.stringify(selectedThread));
   }, [selectedThread]);*/
  const [staffLoading, setStaffLoading] = useState(false);
  const [previousThreads, setPreviousThreads] = useState(JSON.parse(window.localStorage.getItem("previousThreads")));
  useEffect(() => {
    window.localStorage.setItem("previousThreads", JSON.stringify(previousThreads));
  }, [previousThreads]);
  const [archiveThreads, setArchiveThreads] = useState(JSON.parse(window.localStorage.getItem("archiveThreads")));
  useEffect(() => {
    window.localStorage.setItem("archiveThreads", JSON.stringify(archiveThreads));
  }, [archiveThreads]);
  const [partnerships, setPartnerships] = useState(JSON.parse(window.localStorage.getItem("partnerships")));
  useEffect(() => {
    window.localStorage.setItem("partnerships", JSON.stringify(partnerships));
  }, [partnerships]);
  const [emojis, setEmojis] = useState(JSON.parse(window.localStorage.getItem("emojis")));
  useEffect(() => {
    window.localStorage.setItem("emojis", JSON.stringify(emojis));
  }, [emojis]);
  const [staffUsers, setStaffUsers] = useState(JSON.parse(window.localStorage.getItem("staffUsers")));
  useEffect(() => {
    window.localStorage.setItem("staffUsers", JSON.stringify(staffUsers));
  }, [staffUsers]);
  const [previousPageNumber, setPreviousPageNumber] = useState(JSON.parse(window.localStorage.getItem("previousPageNumber")) ?? 1);
  const [previousSearch, setPreviousSearch] = useState("");
  const [archiveSearch, setArchiveSearch] = useState("");
  useEffect(() => {
    window.localStorage.setItem("previousPageNumber", previousPageNumber);
  }, [previousPageNumber]);

  const [previousShowNext, setPreviousShowNext] = useState(JSON.parse(window.localStorage.getItem("previousShowNext")) ?? false);
  useEffect(() => {
    window.localStorage.setItem("previousShowNext", previousShowNext);
  }, [previousShowNext]);

  const [previousPageLoading, setPreviousPageLoading] = useState(false);
  const [archivePageNumber, setArchivePageNumber] = useState(JSON.parse(window.localStorage.getItem("archivePageNumber")) ?? 1);
  useEffect(() => {
    window.localStorage.setItem("archivePageNumber", archivePageNumber);
  }, [archivePageNumber]);

  const [archiveShowNext, setArchiveShowNext] = useState(JSON.parse(window.localStorage.getItem("archiveShowNext")) ?? false);
  useEffect(() => {
    window.localStorage.setItem("archiveShowNext", archiveShowNext);
  }, [archiveShowNext]);

  const [archivePageLoading, setArchivePageLoading] = useState(false);
  const dateOnline = JSON.parse(window.localStorage.getItem("dateOnlineValue"));
  const [dateOnlineValue, setDateOnlineValue] = useState(dateOnline ? new Date(dateOnline) : null);
  useEffect(() => {
    window.localStorage.setItem("dateOnlineValue", JSON.stringify(dateOnlineValue));
  }, [dateOnlineValue]);
  const [activeThreads, setActiveThreads] = useState(JSON.parse(window.localStorage.getItem("activeThreads")));
  useEffect(() => {
    window.localStorage.setItem("activeThreads", JSON.stringify(activeThreads));
  }, [activeThreads]);
  const [otherThreads, setOtherThreads] = useState(JSON.parse(window.localStorage.getItem("otherThreads")));
  useEffect(() => {
    window.localStorage.setItem("otherThreads", JSON.stringify(otherThreads));
  }, [otherThreads]);
  const [queueThreads, setQueueThreads] = useState(JSON.parse(window.localStorage.getItem("queueThreads")));
  useEffect(() => {
    window.localStorage.setItem("queueThreads", JSON.stringify(queueThreads));
  }, [queueThreads]);
  useEffect(() => {
    const unsubscribeAuthState = onAuthStateChanged(auth, intializeUser);
    return () => {
      unsubscribeAuthState && unsubscribeAuthState();
      unsubscribeActiveThreads.current && unsubscribeActiveThreads.current();
      unsubscribeOtherThreads.current && unsubscribeOtherThreads.current();
      unsubscribeQueueThreads.current && unsubscribeQueueThreads.current();
    };
  }, []);

  function setUserOnline(userProfile) {
    setOnline(userProfile.online_flag === 1);
    if (userProfile.online_expiration_date !== 0 || userProfile.offline_expiration_date !== 0) {
      setDateOnlineValue(
        userProfile.online_expiration_date !== 0 ? new Date(userProfile.online_expiration_date) : new Date(userProfile.offline_expiration_date)
      );
      setDateOptionValue(userProfile.online_expiration_date !== 0 ? 1 : 2);
    } else {
      setDateOnlineValue(null);
      setDateOptionValue(1);
    }
  }

  const intializeUser = async (u) => {
    if (u && !u.isAnonymous) {
      const userProfile = JSON.parse(window.localStorage.getItem("user"));
      if (!userProfile) {
        doSignOut();
      } else {
        setUserLoggedIn(true);
        setCurrentUser({ ...u });
        getPartnerships();
        startQueueSnapchot();
        startOtherSnapchot(userProfile);
        startActiveSnapchot(userProfile);
        getPreviousThreads(1, previousSearch);
        getArchiveThreads(1, archiveSearch);
        getStaff();
        getEmojis();
        setUserOnline(userProfile);
        sendToken();
      }

      // deal with the user's online / expiration date
    } else {
      setCurrentUser(null);
      setUserLoggedIn(false);
      setUser(null);
      unsubscribeActiveThreads.current && unsubscribeActiveThreads.current();
      unsubscribeOtherThreads.current && unsubscribeOtherThreads.current();
      unsubscribeQueueThreads.current && unsubscribeQueueThreads.current();
      setSelectedThread(null);
      setDateOnlineValue(null);
      setDateOptionValue(1);
      if (u && u.isAnonymous) {
        doSignOut();
      }
    }
    setLogingLoading(false);
  };
  const audio = new Audio("/LyfSupport_NewMessage.mp3");
  var alreadyNotifiedQueueThreads = [];
  async function startQueueSnapchot() {
    console.log("START LISTENING Queue");
    const q2 = query(collection(db, "sessions"), where("status", "==", 2));
    unsubscribeQueueThreads.current = onSnapshot(
      q2,
      (querySnapshot) => {
        var currentdate = new Date();
        console.log("Last Queue Sync: " + currentdate.getHours() + ":" + currentdate.getMinutes() + ":" + currentdate.getSeconds());
        var list = [];
        var playNotificationSound = false;
        querySnapshot.forEach((document) => {
          try {
            var dta = document.data();
            var inx = -1;
            if (dta.clientPartnershipId) {
              inx = partnerships.findIndex((p) => p.partnership_id === dta.clientPartnershipId);
            }
            var thread = {
              thread_id: dta.threadID,
              expiration_date: 0,
              users: [
                {
                  user_id: dta.clientUserID,
                  display_name: dta.clientDisplayName,
                  email: dta.clientEmail,
                  avatar_url: dta.clientAvatarUrl,
                  partnership: inx != -1 ? partnerships[inx] : null,
                },
              ],
              public_key: dta.publicKey,
              encoded_key: dta.encodedKey,
              last_message: {
                message_id: "",
                text: "",
              },
              status_id: dta.status,
              created_date: dta.createdDate.toDate(),
              support_user_id: dta.supportUserID,
              can_share: dta.canShare,
            };

            if (!alreadyNotifiedQueueThreads.includes(thread.thread_id)) {
              playNotificationSound = true;
            }
            alreadyNotifiedQueueThreads.push(thread.thread_id);

            list.push(thread);
          } catch {}
        });

        list.sort((a, b) => {
          if (a.created_date > b.created_date) return 1;
          if (a.created_date < b.created_date) return -1;
          return 0;
        });
        setQueueThreads(list);
        setQueueLoading(false);
        if (playNotificationSound) {
          audio.play();
        }
        querySnapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            let permission = Notification.permission;
            var baseUrl = process.env.REACT_APP_IS_STAGING ? "https://lyfsupport-demo.web.app" : "https://support.lyf.app";
            const notificationTitle = change.doc.data().clientDisplayName + " has entered the queue.";
            const notificationOptions = {
              body: "",
              icon: `${baseUrl}/logo.png`,
            };
            if (permission === "granted") {
              showNotification(notificationTitle, notificationOptions);
            } else if (permission === "default") {
              Notification.requestPermission(function (permission) {
                if (permission === "granted") {
                  showNotification(notificationTitle, notificationOptions);
                }
              });
            }
          }
        });

        function showNotification(notificationTitle, notificationOptions) {
          var notification = new Notification(notificationTitle, notificationOptions);
          notification.onclick = function () {
            window.focus();
          };
        }
      },
      (error) => {
        location.reload();
      }
    );
  }

  async function startOtherSnapchot(userProfile) {
    if (userProfile) {
      console.log("START LISTENING OTHER SESSIONS");

      const q2 = query(collection(db, "sessions"), where("supportUserID", "!=", userProfile.user_id), where("status", "==", 1));
      unsubscribeOtherThreads.current = onSnapshot(q2, (querySnapshot) => {
        var list = [];
        querySnapshot.forEach((document) => {
          var dta = document.data();
          var inx = -1;
          if (dta.clientPartnershipId) {
            inx = partnerships.findIndex((p) => p.partnership_id === dta.clientPartnershipId);
          }
          var thread = {
            thread_id: dta.threadID,
            expiration_date: dta.expirationDate === null || dta.expirationDate === undefined ? 0 : dta.expirationDate.toDate().getTime(),
            users: [
              {
                user_id: dta.clientUserID,
                display_name: dta.clientDisplayName,
                email: dta.clientEmail,
                avatar_url: dta.clientAvatarUrl,
                partnership: inx != -1 ? partnerships[inx] : null,
              },
            ],
            public_key: dta.publicKey,
            encoded_key: dta.encodedKey,
            last_message: {
              message_id: dta.latestMessage !== null && dta.latestMessage !== undefined ? dta.latestMessage.messageID : "",
              text: dta.latestMessage !== null && dta.latestMessage !== undefined ? dta.latestMessage.text : "",
            },
            status_id: dta.status,
            created_date: dta.createdDate.toDate(),
            support_user_id: dta.supportUserID,
            can_share: dta.canShare,
          };

          list.push(thread);
        });

        setOtherThreads(list);
      });
    } else {
      console.log("USER NOT YET SET, WHY?");
    }
  }

  async function sendToken() {
    if (isSupported()) {
      const messaging = getMessaging();

      getToken(messaging, { vapidKey: firebaseVapidKey })
        .then((currentToken) => {
          if (currentToken) {
            // Send the token to your server and update the UI if necessary
            console.log(currentToken);
            Networker.saveDevice(currentToken);
          } else {
            // Show permission request UI
            console.log("No registration token available. Request permission to generate one.");
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
        });
      onMessage(messaging, (payload) => {
        console.log("Message received. ", payload);
      });
    }
  }
  async function startActiveSnapchot(userProfile) {
    {
      if (userProfile) {
        console.log("START LISTENING ACTIVE SESSIONS");

        const q2 = query(collection(db, "sessions"), where("supportUserID", "==", userProfile.user_id), where("status", "==", 1));
        unsubscribeActiveThreads.current = onSnapshot(q2, (querySnapshot) => {
          var list = [];
          querySnapshot.forEach((document) => {
            var dta = document.data();
            var inx = -1;
            if (dta.clientPartnershipId) {
              inx = partnerships.findIndex((p) => p.partnership_id === dta.clientPartnershipId);
            }
            var thread = {
              thread_id: dta.threadID,
              expiration_date: dta.expirationDate === null || dta.expirationDate === undefined ? 0 : dta.expirationDate.toDate().getTime(),
              users: [
                {
                  user_id: dta.clientUserID,
                  display_name: dta.clientDisplayName,
                  email: dta.clientEmail,
                  avatar_url: dta.clientAvatarUrl,
                  partnership: inx != -1 ? partnerships[inx] : null,
                },
              ],
              public_key: dta.publicKey,
              encoded_key: dta.encodedKey,
              last_message: {
                message_id: dta.latestMessage !== null && dta.latestMessage !== undefined ? dta.latestMessage.messageID : "",
                text: dta.latestMessage !== null && dta.latestMessage !== undefined ? dta.latestMessage.text : "",
              },
              status_id: dta.status,
              created_date: dta.createdDate.toDate(),
              support_user_id: dta.supportUserID,
              can_share: dta.canShare,
            };

            list.push(thread);
          });

          setActiveThreads(list);
        });
      } else {
        console.log("USER NOT YET SET, WHY?");
      }
    }
  }
  async function getPreviousThreads(page, search = "") {
    if (page != previousPageNumber) {
      setPreviousPageNumber(page);
    }
    if (search != previousSearch) {
      setPreviousSearch(search);
    }
    setPreviousPageLoading(true);
    var previousResults = await Networker.getPrevious(page, search);

    if (previousResults === null) {
      previousResults = [];
    }

    setPreviousShowNext(previousResults.length === 20);
    setPreviousPageLoading(false);

    if (page === 1) {
      setPreviousThreads(previousResults);
    } else {
      setPreviousThreads(previousThreads.concat(previousResults));
    }
  }
  async function getArchiveThreads(page, search = "") {
    if (page != archivePageNumber) {
      setArchivePageNumber(page);
    }
    if (search != archiveSearch) {
      setArchiveSearch(search);
    }
    setArchivePageLoading(true);
    var archiveResults = await Networker.getArchiveThreads(page, search);
    if (archiveResults === null) {
      archiveResults = [];
    }

    setArchiveShowNext(archiveResults.length === 20);
    setArchivePageLoading(false);

    if (page === 1) {
      setArchiveThreads(archiveResults);
    } else {
      setArchiveThreads(archiveThreads.concat(archiveResults));
    }
  }
  async function getStaff() {
    setStaffLoading(true);
    var staffResults = await Networker.getStaff();
    setStaffUsers(staffResults);
    setStaffLoading(false);
  }
  async function getEmojis() {
    var emojis = await Networker.getEmojis();
    setEmojis(emojis);
  }

  async function getPartnerships() {
    var emojis = await Networker.getPartnerships();
    setPartnerships(emojis);
  }

  const goOnline = async (user_id, display_name) => {
    var goline = await Networker.goOnline(user_id, display_name);

    if (goline != null) {
      user.online_flag = 1;
      setUser({ ...user });
      setOnline(true);
    }
  };

  async function goOffline() {
    var goOnline = await Networker.goOffline(user.user_id, user.display_name);

    if (goOnline != null) {
      user.online_flag = 0;
      setUser({ ...user });
      setOnline(false);
    }
  }
  const showOnlineMessage = () => {
    MySwal.fire({
      title: "Go Online",
      text: "Before you can accept new sessions, you must go online.  Do you want to go online now?",
      showCancelButton: true,
      confirmButtonText: "Go Online",
    }).then((result) => {
      if (result.value) {
        goOnline(user.user_id, user.display_name);
      }
    });
  };
  const openChat = useCallback(
    async (thread) => {
      if (thread.status_id === 1 || thread.status_id === 3) {
        setSelectedThread(thread);
        handleCloseSidebar();
      } else if (thread.status_id === 2) {
        if (online) {
          // show the user the option to claim the queued thread.
          setSelectedThread(thread);
          handleCloseSidebar();
        } else {
          showOnlineMessage();
        }
      }
    },
    [online, goOnline, setSelectedThread]
  );

  function askChat() {
    MySwal.fire({
      title: "Accept Session",
      text: "Are you sure you want to add this session to your active list?",
      showCancelButton: true,
      confirmButtonText: "Add Session",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        if (online) {
          var newThread = { ...selectedThread };
          newThread.status_id = 1;
          newThread.support_user_id = user.user_id;
          updateThread(newThread);
          Networker.claimChat(newThread, user.user_id);
        } else {
          showOnlineMessage();
        }
      },
    });
  }
  const takeoverChat = useCallback(async () => {
    if (online) {
      // show the user the option to claim the queued thread.
      MySwal.fire({
        title: "Takeover Session",
        text: "Are you sure you want to takeover as the psychologist for this session?",
        showCancelButton: true,
        confirmButtonText: "Takeover Session",
      }).then((result) => {
        if (result.value) {
          Networker.takeSession(selectedThread.thread_id, user.user_id);
          selectedThread.support_user_id = user.user_id;
        }
      });
    } else {
      showOnlineMessage();
    }
  }, [online, user, selectedThread]);
  async function closeThread() {
    await Networker.closeChat(selectedThread);
    setSelectedThread(null);
  }

  async function cancelThread() {
    setSelectedThread(null);
  }
  async function removeStatus(e) {
    e.preventDefault();

    setDateOnlineValue(null);
    setDateOptionValue(1);
    Swal.close();

    await Networker.setStatus(user.user_id, user.display_name, true, 0);
  }

  async function updateStatusDate(opt) {
    var num = fuckYouReactSucksDonkeyBalls.current.getTime();

    setDateOnlineValue(fuckYouReactSucksDonkeyBalls.current);
    setDateOptionValue(opt);

    await Networker.setStatus(user.user_id, user.display_name, parseInt(opt) === 1, num);
  }

  function toggleOnlineStatus() {
    if (online) {
      goOffline();
    } else {
      goOnline(user.user_id, user.display_name);
    }
  }

  function changeOnlineDate(value) {
    const v = "" + value;

    if (v !== "Invalid Date" && value !== null && value !== undefined) {
      console.log(value);
      setDateOnlineValue(value);
    } else {
      const input = document.getElementById("rw_1_input");
      if (input !== null && input !== undefined) {
        const txtValue = input.value;
        if (txtValue !== null && txtValue !== undefined) {
          console.log(txtValue);

          var pattern = /(\d{1,2})\/(\d{1,2})\/(\d{2}), (\d{1,2}):(\d{2}) (am|pm)/;
          var dt = new Date(txtValue.replace(pattern, "20$3-$2-$1 $4:$5 $6"));
          console.log(dt);
          setDateOnlineValue(dt);

          //document.getElementById("rw_1_input").value = txtValue;
        }
      }
    }
  }

  function setNextDate(e) {
    e.preventDefault();

    MySwal.fire({
      title: "Choose Online / Offline Date and Time",
      showCancelButton: true,
      confirmButtonText: "Set",
      html: (
        <div>
          <div className="entryRow">
            <select id="dateOption">
              <option value="1">Online Next</option>
              <option value="2">Offline Next</option>
            </select>
          </div>
          <div className="entryRow">
            <DatePicker
              defaultValue={new Date()}
              min={new Date()}
              parse={(str) => new Date(str)}
              onChange={(value) => changeOnlineDate(value)}
              valueEditFormat={{ dateStyle: "short", timeStyle: "short" }}
              includeTime
            />
          </div>
        </div>
      ),
      footer: (
        <a onClick={removeStatus} href="/">
          Remove Status
        </a>
      ),
    }).then((result) => {
      if (result.value) {
        //var dt = document.getElementsByClassName("rw-widget-input rw-input")[0].value;
        var s = document.getElementById("dateOption");
        var opt = s.options[s.selectedIndex].value;

        updateStatusDate(opt);
      }
    });
  }

  function handleTabChange(tab) {
    // if they tab off the previous tab, be sure to reset the page number
    if (tab !== "previous" && tabID === "previous" && previousPageNumber !== 1) {
      setPreviousPageNumber(1);
    }

    if (tab !== "archive" && tabID === "archive" && archivePageNumber !== 1) {
      setArchivePageNumber(1);
    }

    if (tab === "previous") {
      getPreviousThreads(1, previousSearch);
    } else if (tab === "archive") {
      getArchiveThreads(1, archiveSearch);
    } else if (tab === "staff") {
      getStaff();
    }

    cancelThread();
    setTabID(tab);
  }

  useEffect(() => {
    if (dateOnlineValue) {
      fuckYouReactSucksDonkeyBalls.current = dateOnlineValue;
    } else {
      fuckYouReactSucksDonkeyBalls.current = null;
    }
  }, [dateOnlineValue]);

  const handleCloseSidebar = () => setShowSidebar(false);
  const handleShowSidebar = () => setShowSidebar(true);
  function updateThread(thread) {
    var idx = previousThreads.findIndex((e) => e.thread_id === thread.thread_id);
    if (idx != -1) {
      previousThreads[idx] = thread;
      setPreviousThreads([...previousThreads]);
    }
    idx = archiveThreads.findIndex((e) => e.thread_id === thread.thread_id);
    if (idx != -1) {
      archiveThreads[idx] = thread;
      setArchiveThreads([...archiveThreads]);
    }
    idx = activeThreads.findIndex((e) => e.thread_id === thread.thread_id);
    if (idx != -1) {
      activeThreads[idx] = thread;
      setActiveThreads([...activeThreads]);
    }
    idx = otherThreads.findIndex((e) => e.thread_id === thread.thread_id);
    if (idx != -1) {
      otherThreads[idx] = thread;
      setOtherThreads([...otherThreads]);
    }
    if (selectedThread && selectedThread.thread_id === thread.thread_id) {
      setSelectedThread({ ...thread });
    }
  }
  const value = {
    currentUser,
    userLoggedIn,
    logingLoading,
    user,
    setUser,
    activeThreads,
    otherThreads,
    queueThreads,
    queueLoading,
    previousThreads,
    setPreviousThreads,
    previousPageNumber,
    previousShowNext,
    previousPageLoading,
    previousSearch,
    getPreviousThreads,
    archiveThreads,
    setArchiveThreads,
    archivePageNumber,
    archiveShowNext,
    archivePageLoading,
    archiveSearch,
    getArchiveThreads,
    selectedThread,
    setSelectedThread,
    online,
    updateThread,
    openChat,
    askChat,
    takeoverChat,
    closeThread,
    cancelThread,
    staffUsers,
    staffLoading,
    dateOnlineValue,
    dateOptionValue,
    setNextDate,
    toggleOnlineStatus,
    showSidebar,
    handleShowSidebar,
    handleCloseSidebar,
    handleTabChange,
    emojis,
    setEmojis,
    tabID,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function AuthCustomer({ children }) {
  return <AuthContext.Consumer>{!logingLoading && children}</AuthContext.Consumer>;
}
