import React from "react";
import PreviousCell from "./PreviousCell.js";
import Loader from "react-loader-spinner";
import { Card } from "react-bootstrap";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import "../App.css";
import Spinner from "react-bootstrap/Spinner";
import { AuthContext } from "../Contexts/authContext";
class PreviousList extends React.Component {
  static contextType = AuthContext;
  constructor(props, context) {
    super(props);
  }
  async handleNextPage(e) {
    e.preventDefault();
    const pageNumber = this.props.type === "previous" ? this.context.previousPageNumber : this.context.archivePageNumber;
    const getThreads = this.props.type === "previous" ? this.context.getPreviousThreads : this.context.getArchiveThreads;
    const searchValue = this.props.type === "previous" ? this.context.previousSearch : this.context.archiveSearch;
    await getThreads(pageNumber + 1, searchValue);
    // this.getList(this.state.pageNumber + 1);
  }

  async onSearchPress(e) {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      const getThreads = this.props.type === "previous" ? this.context.getPreviousThreads : this.context.getArchiveThreads;
      await getThreads(1, e.target.value);
    }
  }

  render() {
    const threads = this.props.type === "previous" ? this.context.previousThreads : this.context.archiveThreads;
    const setPreviousThreads = this.props.type === "previous" ? this.context.setPreviousThreads : this.context.setArchiveThreads;
    const showNextPage = this.props.type === "previous" ? this.context.previousShowNext : this.context.archiveShowNext;
    const showPageLoading = this.props.type === "previous" ? this.context.previousPageLoading : this.context.archivePageLoading;

    return (
      <Card className="h-100">
        <Card.Body className="h-100">
          <Card.Title>
            {this.props.tabTitle} {showPageLoading === true && <Spinner animation="border" variant="success" size="sm" />}
          </Card.Title>
          <div className="position-relative mt-2 mb-2">
            <Icon className="fa-icon">search</Icon>
            <input type="text" className="form-control formInputIcon" placeholder="Search ..." onKeyDown={(e) => this.onSearchPress(e)} />
          </div>
          <List sx={{ height: "90%", overflow: "auto" }}>
            {threads && threads.length === 0 && (
              <div className="loader">
                <div>Nothing currently in this tab.</div>
              </div>
            )}
            {threads &&
              threads.map((thread, i) => {
                return (
                  <>
                    {i > 0 && <Divider variant="inset" component="li" />}
                    <PreviousCell
                      key={thread.thread_id}
                      onClick={() => this.context.openChat(thread)}
                      thread={thread}
                      threads={threads}
                      setPreviousThreads={setPreviousThreads}
                      selected={this.context.selectedThread !== null ? this.context.selectedThread.thread_id === thread.thread_id : false}
                    />
                  </>
                );
              })}
            {showNextPage && showPageLoading === false && (
              <div className="threadPreviousNext">
                <a onClick={(e) => this.handleNextPage(e)} href="/">
                  Load More
                </a>
              </div>
            )}
            {showNextPage && showPageLoading === true && (
              <div className="threadPreviousLoader">
                <Loader type="TailSpin" color="green" height={40} width={40} />
              </div>
            )}
          </List>
        </Card.Body>
      </Card>
    );
  }
}

export default PreviousList;
