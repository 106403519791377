import React from "react";
import QueueCell from "./QueueCell.js";
import Loader from "react-loader-spinner";
import "../App.css";
import { Card } from "react-bootstrap";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { AuthContext } from "../Contexts/authContext";

class QueueList extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Card className="h-100">
        <Card.Body className="h-100">
          <Card.Title>Queue</Card.Title>
          <List className="h-75 overflow-auto">
            {this.context.queueLoading === true && (
              <div className="loader">
                <Loader type="TailSpin" color="green" height={40} width={40} />
              </div>
            )}
            {this.context.queueLoading === false && this.context.queueThreads && this.context.queueThreads.length === 0 && (
              <div className="loader">
                <div>Nothing currently in queue.</div>
              </div>
            )}
            {this.context.queueLoading === false &&
              this.context.queueThreads &&
              this.context.queueThreads.map((thread, i) => {
                return (
                  <>
                    {i > 0 && <Divider variant="inset" component="li" />}
                    <QueueCell
                      key={thread.thread_id}
                      onClick={() => this.context.openChat(thread)}
                      thread={thread}
                      selected={this.context.selectedThread !== null ? this.context.selectedThread.thread_id === thread.thread_id : false}
                    />
                  </>
                );
              })}
          </List>
        </Card.Body>
      </Card>
    );
  }
}

export default QueueList;
