import React from "react";
import "../App.css";
const avatarIcon = require("../Assets/user.png");

class StaffCell extends React.Component {
  componentDidMount() {}

  componentDidUpdate() {}

  render() {
    var userAvatar = this.props.user.avatar_url === "" ? null : this.props.user.avatar_url;
    var img = userAvatar ?? avatarIcon;
    const styles = {
      backgroundImage: "url(" + img + ")",
    };

    var statusText = this.props.user.online_flag === 1 ? "Online" : "Offline";
    if (this.props.user.online_expiration_date !== 0) {
      const date = new Date(this.props.user.online_expiration_date);
      statusText =
        "Online @ " +
        String(date.getDate()).padStart(2, "0") +
        "/" +
        String(date.getMonth() + 1).padStart(2, "0") +
        "/" +
        date.getFullYear() +
        " " +
        String(date.getHours()).padStart(2, "0") +
        ":" +
        String(date.getMinutes()).padStart(2, "0");
    } else if (this.props.user.offline_expiration_date !== 0) {
      const date = new Date(this.props.user.offline_expiration_date);
      statusText =
        "Offline @ " +
        String(date.getDate()).padStart(2, "0") +
        "/" +
        String(date.getMonth() + 1).padStart(2, "0") +
        "/" +
        date.getFullYear() +
        " " +
        String(date.getHours()).padStart(2, "0") +
        ":" +
        String(date.getMinutes()).padStart(2, "0");
    }

    return (
      <div className="staffCell">
        <div className="avatarWrapper">
          <div className="avatar" style={styles}></div>
          <div className={this.props.user.online_flag === 1 ? "online" : "offline"} />
        </div>
        <div className="info">
          <h1>{this.props.user.name}</h1>
          <p>{statusText}</p>
        </div>
      </div>
    );
  }
}

export default StaffCell;
