import React from "react";
import "../App.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Loader from "react-loader-spinner";
import MessageCell from "./MessageCell.js";
import { Button, ButtonGroup, Navbar, Container, Modal, ListGroup } from "react-bootstrap";
import Avatar from "@mui/material/Avatar";
import { IconButton, Icon } from "@mui/material";
import { AuthContext } from "../Contexts/authContext";

let timer = null;
const MySwal = withReactContent(Swal);
const avatarIcon = require("../Assets/user.png");
const Networker = require("../Networker.js");
function hexToHSL(H, lighter) {
  // Convert hex to RGB first
  let r = 0,
    g = 0,
    b = 0;
  if (H.length == 4) {
    r = "0x" + H[1] + H[1];
    g = "0x" + H[2] + H[2];
    b = "0x" + H[3] + H[3];
  } else if (H.length == 7) {
    r = "0x" + H[1] + H[2];
    g = "0x" + H[3] + H[4];
    b = "0x" + H[5] + H[6];
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;

  if (delta == 0) h = 0;
  else if (cmax == r) h = ((g - b) / delta) % 6;
  else if (cmax == g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);
  if (lighter > 0) {
    l = l + (l * lighter) / 100;
    if (l > 100) l = 100;
  }
  return "hsl(" + h + "," + s + "%," + l + "%)";
}
class ChatHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loadingMessages: false,
      sessions: null,
      selectedSession: null,
      messages: [],
      show: true,
    };
    this.changeSession = this.changeSession.bind(this);
  }

  handleClose(e) {
    if (e) {
      e.preventDefault();
    }
    this.setState((state) => ({ show: false }));
    this.props.close();
  }

  componentDidMount() {
    this.getHistory();
  }

  componentDidUpdate(prevProps) {
    if (this.props.session !== prevProps.session) {
      this.getHistory();
    }
  }

  changeSession(index) {
    this.getMessages(this.state.sessions[index]);

    this.setState((state) => ({
      selectedSession: state.sessions[index],
    }));
  }

  async getHistory() {
    if (this.props.session != null) {
      var results = await Networker.getHistory(this.props.session);

      if (results.length > 0) {
        this.setState((state) => ({
          loading: false,
          sessions: results,
          selectedSession: results[0],
        }));

        this.getMessages(results[0]);
      } else {
        this.setState((state) => ({
          loading: false,
          sessions: [],
          selectedSession: null,
        }));
      }
    }

    this.setState((state) => ({
      loading: false,
    }));
  }

  async getMessages(record) {
    var results = await Networker.getMessagesForThread(record, 0);
    if (results !== null && results[0] !== null) {
      results = results.reverse();
    }
    console.log(results);

    this.setState((state) => ({
      loadingMessages: false,
      messages: results,
    }));
  }

  render() {
    return (
      <Modal show={this.state.show} size="xl" fullscreen="xxl-down" onHide={(e) => this.handleClose(e)}>
        <Modal.Header closeButton>
          <Modal.Title>Chat History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column h-100">
            <div className="mb-2">
              {this.state.loading === true && (
                <div className="w-100  p-3 text-center">
                  <Loader type="TailSpin" color="green" height={40} width={40} />
                </div>
              )}
              {this.state.loading === false && (
                <ListGroup as="ol" numbered>
                  <div className="d-flex" style={{ overflowX: "auto" }}>
                    {this.state.sessions &&
                      this.state.sessions.map((session, index) => {
                        return (
                          <ChatHistorySession
                            key={session.thread_id}
                            session={session}
                            active={session.thread_id === this.state.selectedSession.thread_id}
                            tapped={this.changeSession}
                            index={index}
                          />
                        );
                      })}
                  </div>
                </ListGroup>
              )}
            </div>

            <div className="flex-grow-1 cellHolder ps-3 pe-3 pt-1" style={{ height: "57vh", overflowY: "auto" }}>
              {this.state.loadingMessages === true && this.state.selectedSession && <Loader type="TailSpin" color="green" height={40} width={40} />}
              {this.state.loadingMessages === false && this.state.selectedSession && (
                <ul className="list-unstyled">
                  {this.state.messages &&
                    this.state.messages.map((message) => {
                      return <MessageCell key={message.message_id} thread={this.state.selectedSession} message={message} />;
                    })}
                </ul>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <p className="me-auto">
            <strong>Note:</strong>&nbsp;
            {this.state.selectedSession ? this.state.selectedSession.note : ""}
          </p>
          <Button onClick={(e) => this.handleClose(e)}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

class ChatHistorySession extends React.Component {
  handleTap(e) {
    e.preventDefault();
    this.props.tapped(this.props.index);
  }

  render() {
    var displayName = "@Random_Lyfer";

    if (this.props.session.users !== undefined && this.props.session.users !== null) {
      if (this.props.session.users.length > 0) {
        var user = this.props.session.users[0];
        if (user.display_name !== "" && user.display_name !== null && user.display_name !== undefined) {
          displayName = "@" + user.display_name;
        } else if (user.name !== "" && user.name !== null && user.name !== undefined) {
          displayName = user.name;
        }
      }
    }
    const date = new Date(this.props.session.created_date);
    return (
      <Button variant={this.props.active ? "success" : "none"} onClick={(e) => this.handleTap(e)}>
        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
          <div className="ms-2 me-auto">
            <div className="fw-bold">{displayName}</div>
            {String(date.getDate()).padStart(2, "0")}/{String(date.getMonth() + 1).padStart(2, "0")}/{date.getFullYear()}{" "}
            {String(date.getHours()).padStart(2, "0")}:{String(date.getMinutes()).padStart(2, "0")}
          </div>
        </ListGroup.Item>
      </Button>
    );
  }
}

class Toolbar extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = { hours: 0, minutes: 0, seconds: 0, showHistory: false, showPartnershipDetails: false };
    this.closeHistory = this.closeHistory.bind(this);
  }

  componentDidMount() {
    this.updateTimeRemaining();
  }

  componentDidUpdate(prevProps) {
    if (this.props.expiration_date !== prevProps.expiration_date) {
      this.updateTimeRemaining();
    }
  }

  componentWillUnmount() {
    clearTimeout(timer);
  }

  async saveNote(note) {
    await Networker.addNote(this.context.selectedThread, note);
    this.closeThread();
  }

  async createReport(note) {
    if (this.context.selectedThread.users.length > 0) {
      await Networker.reportUser(this.context.selectedThread.users[0].user_id, note);
    }

    this.closeThread();
  }

  async blockUser() {
    if (this.context.selectedThread.users.length > 0) {
      await Networker.blockUser(this.context.selectedThread.users[0].user_id);
    }

    this.closeThread();
  }

  handleCloseSession(e) {
    e.preventDefault();

    MySwal.fire({
      title: "Close Session",
      text: "Closing this session will end it.  If the user wishes to message again, they will need to start a new session.  Are you sure you want to close this session?",
      showCancelButton: true,
      confirmButtonText: "Close Session",
    }).then((result) => {
      if (result.value) {
        // this is where we close the session, force an update
        MySwal.fire({
          title: "Note",
          text: "Summarize the session to help with future conversations with this user.",
          input: "textarea",
          showCancelButton: true,
          confirmButtonText: "Save",
          showLoaderOnConfirm: true,
          inputAttributes: { maxlength: 2048 },
          cancelButtonText: "Add later",
          inputValidator: (value) => {
            if (!value) {
              return "Note is required.";
            }
          },
          preConfirm: async (value) => {
            if (value) {
              await this.saveNote(value);
            }
          },
        });
      }
    });
  }

  handleReportUser(e) {
    e.preventDefault();

    MySwal.fire({
      title: "Report User",
      text: "Let us know what the user did to deserve being reported:",
      input: "textarea",
      showCancelButton: true,
      confirmButtonText: "Report",
      inputValidator: (value) => {
        if (!value) {
          return "Note is required.";
        }
      },
    }).then((result) => {
      if (result.value) {
        this.createReport(result.value);
      }
    });
  }

  handleBlockUser(e) {
    e.preventDefault();

    MySwal.fire({
      title: "Block User",
      text: "Blocking this user will prevent them from having any future access to the Lyf Support system.  Are you sure you want to block them?",
      showCancelButton: true,
      confirmButtonText: "Block",
    }).then((result) => {
      if (result.value) {
        this.blockUser();
      }
    });
  }

  handleHistory(e) {
    e.preventDefault();

    this.setState((state) => ({
      showHistory: !state.showHistory,
    }));
  }

  closeHistory() {
    this.setState((state) => ({
      showHistory: false,
    }));
  }

  async updateTimeRemaining() {
    clearTimeout(timer);

    // is there even an expiration date?
    if (this.props.expiration_date) {
      var now = new Date();
      var exp_date = new Date(this.props.expiration_date);
      var sec = Math.floor((exp_date.getTime() - now.getTime()) / 1000);
      var min = 0;
      var hr = 0;
      var aboutToExpire = true;

      if (sec <= 0) {
        sec = -1 * sec;

        min = Math.floor(sec / 60);
        sec = sec - 60 * min;

        hr = Math.floor(min / 60);
        min = min - 60 * hr;

        sec = -1 * sec;
        min = -1 * min;
        hr = -1 * hr;
      } else {
        min = Math.floor(sec / 60);
        sec = sec - 60 * min;

        hr = Math.floor(min / 60);
        min = min - 60 * hr;
      }

      this.setState((state) => ({
        hours: hr,
        minutes: min,
        seconds: sec,
      }));
      if (sec > 0 || min > 0 || hr > 0) {
        timer = setTimeout(() => this.updateTimeRemaining(), 1000);
      } else {
        if (aboutToExpire === true && this.context.selectedThread.status_id !== 3) {
          aboutToExpire = false;
          // within 5 minutes of expiration
          MySwal.fire({
            title: "Session Expired",
            text: "This session has expired, however the user may add time within the next 5 minutes.  If you wait, we will re-activate the session if the user adds time.  Otherwise, click Close Session to end the session now.",
            showCancelButton: false,
            confirmButtonText: "Close Session",
          }).then((result) => {
            if (result.value) {
              // this is where we close the session, force an update
              MySwal.fire({
                title: "Note",
                text: "Summarize the session to help with future conversations with this user.",
                input: "textarea",
                showCancelButton: true,
                confirmButtonText: "Save",
                showLoaderOnConfirm: true,
                inputAttributes: { maxlength: 2048 },
                cancelButtonText: "Add later",
                inputValidator: (value) => {
                  if (!value) {
                    return "Note is required.";
                  }
                },
                preConfirm: async (value) => {
                  if (value) {
                    await this.saveNote(value);
                  }
                },
              });
            }

            /*if (result.value) {
                            // this is where we close the session, force an update
                            this.closeThread();
                        }*/
          });

          // timer = setTimeout(() => this.closeThread(), 1000 * 5 * 60);
        }
      }
    } else {
    }
  }

  closeThread() {
    Swal.close();
    this.context.closeThread();
  }

  handleTakeoverSession(e) {
    e.preventDefault();
    this.context.takeoverChat();
  }

  render() {
    var lbl = "";
    var showButtons = true;
    var ismine = this.context.selectedThread.support_user_id === this.context.user.user_id;

    if (this.context.selectedThread.status_id === 3) {
      lbl = "Closed";
      showButtons = false;
    } else {
      if (this.props.expiration_date) {
        if (this.state.hours <= 0 && this.state.minutes <= 0 && this.state.seconds <= 0) {
          const date = new Date(this.props.expiration_date);
          lbl =
            "Expired @ " +
            String(date.getDate()).padStart(2, "0") +
            "/" +
            String(date.getMonth() + 1).padStart(2, "0") +
            "/" +
            date.getFullYear() +
            " " +
            String(date.getHours()).padStart(2, "0") +
            ":" +
            String(date.getMinutes()).padStart(2, "0");
          showButtons = false;
        } else {
          if (this.context.selectedThread.status_id === 2) {
            lbl = "Queued";
            showButtons = false;
          } else {
            if (this.state.hours > 0) {
              lbl += this.state.hours + "h ";
            }
            if (this.state.minutes > 0) {
              lbl += this.state.minutes + "m ";
            }

            lbl += this.state.seconds + "s Remaining";
          }
        }
      } else {
        lbl = "Pending";
        if (this.context.selectedThread.status_id === 2 || this.context.selectedThread.status_id === 3) {
          showButtons = false;
        }
      }
    }

    var userAvatar =
      this.context.selectedThread.users.length > 0
        ? this.context.selectedThread.users[0].avatar_url === ""
          ? null
          : this.context.selectedThread.users[0].avatar_url
        : null;
    var img = userAvatar ?? avatarIcon;
    const styles = {
      backgroundImage: "url(" + img + ")",
    };
    var colorPartnership =
      this.context.selectedThread.users.length > 0 && this.context.selectedThread.users[0].partnership
        ? this.context.selectedThread.users[0].partnership.color
        : "";
    var namePartnership =
      this.context.selectedThread.users.length > 0 && this.context.selectedThread.users[0].partnership
        ? this.context.selectedThread.users[0].partnership.partnership_name
        : "";
    var logoPartnership =
      this.context.selectedThread.users.length > 0 && this.context.selectedThread.users[0].partnership
        ? this.context.selectedThread.users[0].partnership.logo
        : "";
    var descriptionPartnership =
      this.context.selectedThread.users.length > 0 && this.context.selectedThread.users[0].partnership
        ? this.context.selectedThread.users[0].partnership.description
        : "";
    var websitePartnership =
      this.context.selectedThread.users.length > 0 && this.context.selectedThread.users[0].partnership
        ? this.context.selectedThread.users[0].partnership.website
        : "";
    var linksPartnership =
      this.context.selectedThread.users.length > 0 && this.context.selectedThread.users[0].partnership
        ? this.context.selectedThread.users[0].partnership.links
        : [];
    return (
      <React.Fragment>
        <Navbar className="bg-body-tertiar">
          <Container>
            {showButtons && ismine && (
              <ButtonGroup>
                <Button variant="none" style={{ color: "#E58D8A" }} onClick={(e) => this.handleCloseSession(e)}>
                  Close Session
                </Button>
                <Button variant="none" style={{ color: "#505050" }} onClick={(e) => this.handleReportUser(e)}>
                  Report User
                </Button>
                <Button variant="none" style={{ color: "#505050" }} onClick={(e) => this.handleBlockUser(e)}>
                  Block User
                </Button>

                <IconButton onClick={(e) => this.props.refreshMessages()}>
                  <Icon>refresh</Icon>
                </IconButton>
              </ButtonGroup>
            )}
            {showButtons && !ismine && (
              <ButtonGroup>
                <Button variant="none" className="bg-lyfsupport text-white" onClick={(e) => this.handleTakeoverSession(e)}>
                  Takeover Session
                </Button>
              </ButtonGroup>
            )}
            {!showButtons && this.context.selectedThread.status_id === 2 && (
              <ButtonGroup>
                <Button variant="none" className="bg-lyfsupport text-white" onClick={() => this.context.askChat()}>
                  Take This Session
                </Button>
              </ButtonGroup>
            )}
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
              <Navbar.Text className="text-black me-2">
                <div className="d-flex flex-column">
                  <div>
                    <strong>Status: </strong> {lbl}
                  </div>
                  {this.context.selectedThread.status_id === 1 && namePartnership !== "" && this.context.selectedThread.can_share !== undefined && (
                    <div>
                      <strong style={{ color: this.context.selectedThread.can_share ? "#6DC1A4" : "#E58D8A" }}>
                        {this.context.selectedThread.can_share ? "Consent Given" : "Consent Not Given"}
                      </strong>
                    </div>
                  )}
                </div>
              </Navbar.Text>
              <Button variant="none" onClick={(e) => this.handleHistory(e)}>
                <Avatar src={img} sx={{ width: 30, height: 30 }} />
              </Button>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        {colorPartnership !== "" && (
          <>
            <div className="d-flex w-100 justify-content-center align-items-center" style={{ height: "54px", backgroundColor: colorPartnership }}>
              <div className="text-white text-wrap fw-bold">
                <span
                  onClick={() => this.setState({ showPartnershipDetails: websitePartnership ? true : false })}
                  style={{ cursor: websitePartnership ? "pointer" : "auto" }}
                >
                  {"Partnership with " + namePartnership} {logoPartnership && <img src={logoPartnership} width={30} height={30} />}
                </span>
              </div>
            </div>
            {this.state.showPartnershipDetails && websitePartnership && (
              <div style={{ position: "relative" }}>
                <div
                  className="d-flex flex-column w-100 justify-content-center align-items-center text-white text-wrap"
                  style={{ backgroundColor: hexToHSL(colorPartnership, 50), paddingBottom: "10px", position: "relative" }}
                >
                  <div className="">
                    <img src="Copy.svg" width={15} style={{ cursor: "pointer" }} onClick={() => navigator.clipboard.writeText(websitePartnership)} />
                    <a
                      href={websitePartnership}
                      target="blank"
                      className="ms-1"
                      style={{ borderBottom: "1px", borderBottomStyle: "solid", textDecoration: "none", color: "white" }}
                    >
                      {"View website"}
                    </a>
                  </div>
                  <div className="" style={{ whiteSpace: "pre-line" }}>
                    {descriptionPartnership}
                  </div>
                  {linksPartnership &&
                    linksPartnership.map((link) => (
                      <div className="">
                        <img
                          src="Copy.svg"
                          className="me-1"
                          width={15}
                          style={{ cursor: "pointer" }}
                          onClick={() => navigator.clipboard.writeText(link.link)}
                        />
                        {link.description}
                      </div>
                    ))}
                </div>
                <IconButton
                  onClick={() => this.setState({ showPartnershipDetails: false })}
                  style={{ height: 20, width: 20, color: "white", position: "absolute", right: 5, top: 0 }}
                >
                  <Icon>close</Icon>
                </IconButton>
              </div>
            )}
          </>
        )}

        {this.state.showHistory && <ChatHistory session={this.context.selectedThread} close={this.closeHistory} />}
      </React.Fragment>
    );
  }
}

export default Toolbar;
