import React from "react";
import LyfSupportBadge from "../Utils/LyfSupportBadge";
import MuiAvatar from "@mui/material/Avatar";
const avatarIcon = require("../Assets/user.png");
export default function Avatar(props) {
  var userAvatar = props.user ? (props.user.avatar_url === "" ? null : props.user.avatar_url) : null;
  var img = userAvatar ?? avatarIcon;
  var isPartner = props.user && props.user.email ? (props.user.email.endsWith("@lyfsupport.app") ? true : false) : false;
  var colorPartnership = props.user && props.user.partnership ? props.user.partnership.color : "";
  if (isPartner) {
    return (
      <LyfSupportBadge color="#42a5f5">
        <MuiAvatar src={img} />
      </LyfSupportBadge>
    );
  } else if (colorPartnership !== "") {
    return (
      <LyfSupportBadge color={colorPartnership}>
        <MuiAvatar src={img} />
      </LyfSupportBadge>
    );
  } else {
    return <MuiAvatar src={img} />;
  }
}
