import React from "react";
import ThreadCell from "./ThreadCell.js";
import "../App.css";
import { Card } from "react-bootstrap";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { AuthContext } from "../Contexts/authContext";
class OtherActiveList extends React.Component {
  static contextType = AuthContext;
  render() {
    return (
      <Card className="h-100">
        <Card.Body className="h-100">
          <Card.Title>Other Sessions</Card.Title>
          <List className="h-75 overflow-auto">
            {this.context.otherThreads && this.context.otherThreads.length === 0 && (
              <div className="loader">
                <div>No other active sessions.</div>
              </div>
            )}
            {this.context.otherThreads &&
              this.context.otherThreads.map((thread, i) => {
                return (
                  <>
                    {i > 0 && <Divider variant="inset" component="li" />}
                    <ThreadCell
                      key={thread.thread_id}
                      onClick={() => this.context.openChat(thread)}
                      thread={thread}
                      selected={this.context.selectedThread !== null ? this.context.selectedThread.thread_id === thread.thread_id : false}
                    />
                  </>
                );
              })}
          </List>
        </Card.Body>
      </Card>
    );
  }
}

export default OtherActiveList;
