import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
const firebaseConfig =
  process.env.REACT_APP_IS_STAGING === "true"
    ? {
        apiKey: "AIzaSyDdKEDOGIrmp46bTL0dg_cbxA1PUMjn5nM",
        authDomain: "lyf-app-demo.firebaseapp.com",
        databaseURL: "https://lyf-app-demo.firebaseio.com",
        projectId: "lyf-app-demo",
        storageBucket: "lyf-app-demo.appspot.com",
        messagingSenderId: "693969527817",
        appId: "1:693969527817:web:64045045d7dbd1762c84d8",
        measurementId: "G-YJ79LXMN8S",
      }
    : {
        apiKey: "AIzaSyCvtlBKATANRPgMUeg8ABsAWl59m0if_RI",
        authDomain: "incogo-com.firebaseapp.com",
        databaseURL: "https://incogo-com.firebaseio.com",
        projectId: "incogo-com",
        storageBucket: "incogo-com.appspot.com",
        messagingSenderId: "713993304604",
        appId: "1:713993304604:web:8c631732071b42642cbdd9",
        measurementId: "G-7RBFHTRD38",
      };
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const firebaseVapidKey =
  process.env.REACT_APP_IS_STAGING === "true"
    ? "BLPHXioYbsu7OIMx6ewaXmXsGKUxFDR-t1QxPmWrqM4nBduIk6N2JmLf0jVSk77Q867RyNiDR43In1JUfR4f2DI"
    : "BJyOZAAMRRuTjOFALMeyCivDp8byUSQL1RQ89JtVLIbrwi4quqelYT5n9MzYDB47T9NQOiJkN2Y6HBD408Mrgow";
export { app, auth, db, firebaseVapidKey };
