import React, { useState, useEffect } from "react";
import Switch from "react-switch";
import SignInForm from "./Components/SignInForm.js";
import CenterNav from "./Components/CenterNav.js";
import QueueList from "./Components/QueueList.js";
import ActiveSessionList from "./Components/ActiveSessionList.js";
import OtherActiveList from "./Components/OtherActiveList.js";
import StaffList from "./Components/StaffList.js";
import PreviousList from "./Components/PreviousList.js";
import CurrentUser from "./Components/CurrentUser.js";
import { Button, Navbar, Stack, Nav, Offcanvas } from "react-bootstrap";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import { db } from "./firebase/firebase.js";
import { useAuth } from "./Contexts/authContext/index.jsx";
import Loader from "react-loader-spinner";

import "./App.css";
import "react-widgets/styles.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#67A3A9",
    },
    text: {
      primary: "#fff",
    },
  },
});

function App() {
  const {
    userLoggedIn,
    user,
    selectedThread,
    online,
    showSidebar,
    handleCloseSidebar,
    handleShowSidebar,
    handleTabChange,
    toggleOnlineStatus,
    tabID,
    dateOnlineValue,
    setNextDate,
    dateOptionValue,
    logingLoading,
  } = useAuth();

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        {!userLoggedIn && !logingLoading && <SignInForm />}
        {logingLoading && (
          <div className="d-flex vh-100 flex-column justify-content-center align-items-center">
            <div>
              <Loader type="TailSpin" color="green" height={40} width={40} />
            </div>
          </div>
        )}
        {userLoggedIn && !logingLoading && (
          <div className="App-main d-flex flex-column">
            <Navbar className="shadow" collapseOnSelect expand="lg" bg="light" variant="light">
              <Navbar.Brand className="ms-2" href="#">
                <Button variant="light" onClick={handleShowSidebar}>
                  <Avatar
                    src="/logo.png"
                    className="d-inline-block align-top"
                    sx={{
                      width: {
                        xs: 40,
                        sm: 45,
                      },
                      height: {
                        xs: 40,
                        sm: 45,
                      },
                    }}
                  />
                </Button>
              </Navbar.Brand>
              <CurrentUser online={online} />
              <Navbar.Toggle className="me-2" />

              <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end me-2">
                <Stack direction="horizontal" gap={5}>
                  <div className="statusWrapper">
                    <Stack>
                      <div>
                        {dateOnlineValue === null ? (
                          "Next Online/Offline:"
                        ) : dateOptionValue === 1 ? (
                          <div>
                            Next <b>Online</b>:
                          </div>
                        ) : (
                          <div>
                            Next <b>Offline</b>:
                          </div>
                        )}
                      </div>
                      <a onClick={setNextDate} href="/">
                        {dateOnlineValue === null
                          ? "Set"
                          : String(dateOnlineValue.getDate()).padStart(2, "0") +
                            "/" +
                            String(dateOnlineValue.getMonth() + 1).padStart(2, "0") +
                            "/" +
                            dateOnlineValue.getFullYear() +
                            " " +
                            String(dateOnlineValue.getHours()).padStart(2, "0") +
                            ":" +
                            String(dateOnlineValue.getMinutes()).padStart(2, "0")}
                      </a>
                    </Stack>
                  </div>
                  <div className="vr"></div>
                  <div className="switchWrapper">
                    <Stack>
                      <div>Current Status:</div>
                      <div>
                        <b className={online ? "online" : "offline"}>{online ? "Online" : "Offline"}</b>
                      </div>
                    </Stack>
                  </div>
                  <Switch
                    onChange={toggleOnlineStatus}
                    checked={online}
                    onColor="#86d3ff"
                    onHandleColor="#2693e6"
                    handleDiameter={30}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={20}
                    width={48}
                    className="reactSwitch"
                  />
                </Stack>
              </Navbar.Collapse>
            </Navbar>
            <div className="App-body flex-fill d-flex">
              <Offcanvas show={showSidebar} onHide={handleCloseSidebar} responsive="lg" className="h-100 flex-shrink-0">
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="h-100 ">
                  <div
                    className="d-flex flex-column shadow pt-2 ps-2 ps-lg-4 pe-2 pe-lg-4 pb-4"
                    style={{
                      backgroundColor: "#e0e0e0",
                      borderTopLeftRadius: "15px",
                      borderTopRightRadius: "15px",
                      height: "99%",
                    }}
                  >
                    <Nav
                      className="mb-2 flex-nowrap nav-fill"
                      style={{ borderTopLeftRadius: "15px", borderTopRightRadius: "15px" }}
                      defaultActiveKey={tabID}
                      variant="pills"
                    >
                      <Nav.Item>
                        <Nav.Link
                          className={
                            tabID === "session"
                              ? "bg-lyfsupport pe-2 ps-2 pe-sm-3 ps-sm-3 pt-2 pb-2"
                              : "text-lyfsupport pe-2 ps-2 pe-sm-3 ps-sm-3 pt-2 pb-2"
                          }
                          eventKey="session"
                          onClick={() => handleTabChange("session")}
                        >
                          Sessions
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          className={
                            tabID === "previous"
                              ? "bg-lyfsupport pe-2 ps-2 pe-sm-3 ps-sm-3 pt-2 pb-2"
                              : "text-lyfsupport pe-2 ps-2 pe-sm-3 ps-sm-3 pt-2 pb-2"
                          }
                          eventKey="previous"
                          onClick={() => handleTabChange("previous")}
                        >
                          Previous
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          className={
                            tabID === "staff"
                              ? "bg-lyfsupport pe-2 ps-2 pe-sm-3 ps-sm-3 pt-2 pb-2"
                              : "text-lyfsupport pe-2 ps-2 pe-sm-3 ps-sm-3 pt-2 pb-2s"
                          }
                          eventKey="staff"
                          onClick={() => handleTabChange("staff")}
                        >
                          Staff
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          className={
                            tabID === "archive"
                              ? "bg-lyfsupport pe-2 ps-2 pe-sm-3 ps-sm-3 pt-2 pb-2"
                              : "text-lyfsupport pe-2 ps-2 pe-sm-3 ps-sm-3 pt-2 pb-2"
                          }
                          eventKey="archive"
                          onClick={() => handleTabChange("archive")}
                        >
                          Archive
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <div className={tabID === "session" ? "tab_pane active flex-fill d-flex flex-column" : "tab_pane"}>
                      <div className="mb-3" style={{ height: "30%", borderRadius: "15px" }}>
                        <ActiveSessionList />
                      </div>
                      <div className="mb-3" style={{ height: "30%", borderRadius: "15px" }}>
                        <OtherActiveList />
                      </div>
                      <div style={{ height: "30%", borderRadius: "15px" }}>
                        <QueueList />
                      </div>
                    </div>
                    <div className={tabID === "previous" ? "tab_pane active flex-fill" : "tab_pane"}>
                      <PreviousList type="previous" tabTitle="Previous Sessions" />
                    </div>
                    <div className={tabID === "staff" ? "tab_pane active flex-fill" : "tab_pane"}>
                      <StaffList />
                    </div>
                    <div className={tabID === "archive" ? "tab_pane active flex-fill" : "tab_pane"}>
                      <PreviousList type="archive" tabTitle="Archive Sessions" />
                    </div>
                  </div>
                </Offcanvas.Body>
              </Offcanvas>

              <div className="flex-grow-1" style={{ overflowX: "auto" }}>
                <CenterNav selectedThread={selectedThread} />
              </div>
            </div>
          </div>
        )}
      </div>
    </ThemeProvider>
  );
}

export default App;
