import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "../Assets/css/login.css";
import { getCustomToken } from "./../Networker";
import { AuthContext } from "../Contexts/authContext";
import LinearProgress from "@mui/material/LinearProgress";
const logo = require("../Assets/lyf-logo.png");
const MySwal = withReactContent(Swal);

class SignInForm extends React.Component {
  previousContext;
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = { username: "", password: "" };
    this.emailInput = React.createRef();
    this.passwordInput = React.createRef();
  }

  componentDidMount() {
    this.emailInput.current.focus();
    this.previousContext = this.context;
  }

  componentDidUpdate() {
    if (this.previousContext.logingLoading !== this.context.logingLoading) {
      this.setState({ loading: false });
    }

    this.previousContext = this.context;
  }

  handleEmailInput(e) {
    var txt = e.target.value;
    this.setState((state) => ({
      username: txt,
    }));
  }

  handlePasswordInput(e) {
    var txt = e.target.value;
    this.setState((state) => ({
      password: txt,
    }));
  }

  async login(e) {
    try {
      e.preventDefault();

      if (this.state.username === "") {
        MySwal.fire({
          title: "Email is required",
          icon: "warning",
        }).then(() => {
          this.emailInput.current.focus();
        });
      } else if (this.state.password === "") {
        MySwal.fire({
          title: "Password is required",
        }).then(() => {
          this.passwordInput.current.focus();
        });
      } else {
        this.setState((state) => ({
          loading: true,
        }));
        const userToken = await getCustomToken(this.state.username, this.state.password);
        var passed = false;
        if (userToken && userToken.token) {
          this.context.setUser(userToken);
          passed = true;
        }
        if (!passed) {
          this.setState((state) => ({
            password: "",
            loading: false,
          }));

          MySwal.fire({
            title: "Email and/or Password is incorrect.  Please try again.",
            icon: "warning",
          }).then(() => {
            this.passwordInput.current.focus();
          });
        }
      }
    } finally {
    }
  }

  render() {
    return (
      <>
        <div className="limiter">
          <div className="container-login100">
            <div className="wrap-login100">
              <div className="login-logo">
                <img src={logo} alt="" />
              </div>

              <form className="login100-form validate-form" onSubmit={(e) => this.login(e)}>
                <span className="login100-form-title p-b-34">{process.env.REACT_APP_TITLE}</span>
                <div className="wrap-input100 rs1-wrap-input100 validate-input m-b-20" data-validate="Type user name">
                  <input
                    id="first-name"
                    className="input100"
                    type="email"
                    name="username"
                    placeholder="Email"
                    value={this.state.username}
                    autoComplete="off"
                    autoCapitalize="none"
                    autoCorrect="false"
                    onChange={(e) => this.handleEmailInput(e)}
                    ref={this.emailInput}
                  />
                  <span className="focus-input100"></span>
                </div>
                <div className="wrap-input100 rs2-wrap-input100 validate-input m-b-20" data-validate="Type password">
                  <input
                    className="input100"
                    type="password"
                    name="pass"
                    placeholder="Password"
                    autoComplete="off"
                    value={this.state.password}
                    onChange={(e) => this.handlePasswordInput(e)}
                    ref={this.passwordInput}
                  />
                  <span className="focus-input100"></span>
                </div>
                <div className="container-login100-form-btn">
                  <button className="login100-form-btn" disabled={this.state.loading}>
                    Sign in
                  </button>
                </div>

                <div className="w-full text-center p-t-27 ">
                  <img className="txt3" src="/LSA_Logo.png"></img>
                </div>
              </form>
              {this.state.loading && (
                <div className="w-100">
                  <LinearProgress />
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SignInForm;
