import React from "react";
import ChatBox from "./ChatBox.js";
import MessageCell from "./MessageCell.js";
import Toolbar from "./Toolbar.js";
import Loader from "react-loader-spinner";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { setDoc, doc, serverTimestamp, collection, query, where, onSnapshot, getDocs, orderBy } from "firebase/firestore";
import Icon from "@mui/material/Icon";
import { Button, IconButton } from "@mui/material";
import "../App.css";
import { app, db } from "../firebase/firebase";
import { AttachmentViewer } from "./MessageCell.js";
import { AuthContext } from "../Contexts/authContext";
const Networker = require("../Networker.js");
const MySwal = withReactContent(Swal);
var listener;
var messageListener;
class CenterNav extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      messages: null,
      replyMessageSelected: null,
      expiration_date: 0,
      selectedThread: props.selectedThread,
      is_typing: false,
      newMessage: false,
      reload: false,
    };
    this.reloadSession = this.reloadSession.bind(this);
    this.setListner = this.setListner.bind(this);
    this.insertReceiveLog = this.insertReceiveLog.bind(this);
    this.selectReplyMessage = this.selectReplyMessage.bind(this);
    this.deleteReplyMessage = this.deleteReplyMessage.bind(this);
    this.typingChanged = this.typingChanged.bind(this);
    this.isLastMessageVisible = true;
    this.observer = new IntersectionObserver(([entry]) => {
      this.isLastMessageVisible = entry.isIntersecting;
      if (this.isLastMessageVisible && this.state.newMessage) {
        this.setState({ newMessage: false });
      }
    });
    this.abortController = new AbortController();
    this.abortSignal = this.abortController.signal;
  }

  audio = new Audio("/LYF_EllipsisTyping_v02.wav");

  componentDidMount() {
    this.setListner();
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedThread !== prevProps.selectedThread) {
      const new_thread_id = this.props.selectedThread === null ? "" : this.props.selectedThread.thread_id;
      const old_thread_id = prevProps.selectedThread === null ? "" : prevProps.selectedThread.thread_id;
      const new_status_id = this.props.selectedThread === null ? "" : this.props.selectedThread.status_id;
      const old_status_id = prevProps.selectedThread === null ? "" : prevProps.selectedThread.status_id;
      if (new_thread_id !== old_thread_id) {
        this.reloadSession();
      } else if (old_status_id === 2 && new_status_id === 1) {
        this.setListner();
      }
    }
  }

  componentWillUnmount() {
    // clearTimeout(timer);
    this.abortController.abort();
    if (listener !== null && listener !== undefined) {
      listener();
    }
    if (messageListener !== null && messageListener !== undefined) {
      messageListener();
    }
  }

  scrollToBottom() {
    if (this.el) {
      this.el.scrollIntoView({ behavior: "smooth" });
    }
  }

  playSound() {
    this.audio.play();
  }

  selectReplyMessage(message) {
    this.setState({ replyMessageSelected: message });
  }
  deleteReplyMessage() {
    this.setState({ replyMessageSelected: null });
  }
  async insertReceiveLog(message_id, text) {
    await setDoc(
      doc(db, "messagesLog", message_id),
      {
        messageID: message_id,
        receiveDate: serverTimestamp(),
        receiveLocalDate: new Date(),
      },
      { merge: true }
    );
  }
  async reloadSession() {
    if (listener !== null && listener !== undefined) {
      listener();
    }
    if (messageListener !== null && messageListener !== undefined) {
      messageListener();
    }
    listener = null;
    messageListener = null;

    // unsubscribe to the old thread

    this.setState((state) => ({
      messages: [],
      expiration_date: 0,
      selectedThread: this.props.selectedThread,
      is_typing: false,
      replyMessageSelected: null,
      newMessage: false,
      reload: false,
    }));

    if (this.props.selectedThread) {
      console.log("thread:" + this.props.selectedThread.thread_id);
    }

    this.loadMessage().then(() => this.setListner());
  }

  mapMessage(data) {
    var otherUserID = 0;

    if (this.props.selectedThread && this.props.selectedThread.users) {
      if (this.props.selectedThread.users.length > 0) {
        otherUserID = this.props.selectedThread.users[0].user_id;
      }
    }
    var dt = data.sentDate === null || data.sentDate === undefined ? new Date() : data.sentDate.toDate();

    var obj = {
      message_id: data.messageID,
      text: data.text,
      is_raw: true,
      creator: {
        display_name: data.author.displayName,
        user_id: data.author.userID,
        lyf_support_professional_flag: otherUserID === data.author.userID ? 0 : 1,
      },
      reply_message: data.replayMessage
        ? {
            message_id: data.replayMessage.messageID,
            text: data.replayMessage.text,
            is_raw: true,
            creator: {
              display_name: data.replayMessage.author.displayName,
              user_id: data.replayMessage.author.userID,
              lyf_support_professional_flag: otherUserID === data.replayMessage.author.userID ? 0 : 1,
            },
            attachments: data.replayMessage.attachments,
          }
        : null,
      reactions: data.reaction ? [{ message_id: data.messageID, reactionType: data.reaction.reactionType, creator: null }] : [],
      is_system_message: data.type === 1 ? false : true,
      date: dt,
      expiration_date: this.state.expiration_date,
      attachments: data.attachments,
    };
    return obj;
  }

  async typingChanged(isTyping) {
    await Networker.setTyping(this.props.selectedThread.thread_id, isTyping);
  }

  async saveNote(note) {
    await Networker.addNote(this.props.selectedThread, note);
    this.context.cancelThread();
  }
  async loadMessage() {
    if (this.props.selectedThread !== null && (this.props.selectedThread.status_id === 3 || this.props.selectedThread.status_id === 1)) {
      this.setState({
        reload: true,
      });
      const qM = query(collection(db, "messages"), where("sessionID", "==", this.props.selectedThread.thread_id), orderBy("sentDate"));
      var selectedThreadId = this.props.selectedThread.thread_id;
      const querySnapshotM = await getDocs(qM);
      if (!this.props.selectedThread || selectedThreadId !== this.props.selectedThread.thread_id) {
        return;
      }
      var lstMessages = [];
      querySnapshotM.forEach((document) => {
        lstMessages.push(this.mapMessage(document.data()));
      });
      this.setState(
        {
          reload: false,
          messages: lstMessages,
        },
        () => {
          if (this.state.messages.length > 0) {
            this.scrollToBottom();
          }
        }
      );
    }
  }
  async setListner() {
    if (this.props.selectedThread !== null && (this.props.selectedThread.status_id === 2 || this.props.selectedThread.status_id === 1)) {
      if (!listener) {
        const q = query(collection(db, "sessions"), where("threadID", "==", this.props.selectedThread.thread_id));
        listener = onSnapshot(q, (querySnapshot) => {
          querySnapshot.forEach((document) => {
            var dta = document.data();
            if (dta.status !== 2) {
              this.setState((state) => ({
                is_typing: dta.clientUserIsTyping,
                expiration_date: dta.expirationDate === null || dta.expirationDate === undefined ? 0 : dta.expirationDate.toDate().getTime(),
              }));
              if (
                (dta.status === 3 || dta.status === 4) &&
                dta.supportUserClosed === false &&
                this.props.selectedThread.support_user_id === this.context.user.user_id &&
                this.props.selectedThread.status_id === 1
              ) {
                MySwal.fire({
                  title: "Session Closed",
                  text: "The user has closed the session. Summarize the session to help with future conversations with this user.",
                  input: "textarea",
                  showLoaderOnConfirm: true,
                  inputAttributes: { maxlength: 2048 },
                  showCancelButton: true,
                  confirmButtonText: "Save",
                  cancelButtonText: "Add later",
                  inputValidator: (value) => {
                    if (!value) {
                      return "Note is required.";
                    }
                  },
                  preConfirm: async (value) => {
                    if (value) {
                      await this.saveNote(value);
                    }
                  },
                });
              }
              if (
                (this.props.selectedThread.expiration_date == null && dta.expirationDate != null) ||
                (this.props.selectedThread.expiration_date != null && dta.expirationDate == null) ||
                (dta.expirationDate != null &&
                  this.props.selectedThread.expiration_date != null &&
                  dta.expirationDate.seconds != this.props.selectedThread.expiration_date.seconds) ||
                this.props.selectedThread.status_id !== dta.status ||
                this.props.selectedThread.can_share !== dta.canShare ||
                this.props.selectedThread.support_user_id !== dta.supportUserID
              ) {
                var newThread = { ...this.props.selectedThread };
                newThread.expiration_date = dta.expirationDate;
                newThread.status_id = dta.status;
                newThread.can_share = dta.canShare;
                newThread.support_user_id = dta.supportUserID;
                this.context.updateThread(newThread);
              }
            }
          });
        });
      }
      if (this.props.selectedThread.status_id === 1) {
        if (!messageListener) {
          const q2 = query(collection(db, "messages"), where("sessionID", "==", this.props.selectedThread.thread_id));
          messageListener = onSnapshot(q2, (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
              const data = change.doc.data();
              var msgs = [...this.state.messages];
              var changed = false;
              var playsound = false;
              if (change.type === "added") {
                let matchIndex = msgs.findIndex((obj) => obj.message_id === data.messageID);
                if (matchIndex === -1) {
                  const msg = this.mapMessage(data);
                  msgs.push(msg);
                  if (msg.creator && msg.creator.user_id != this.context.user.user_id) {
                    this.insertReceiveLog(msg.message_id, msg.text);
                  }
                  if (!msg.creator.lyf_support_professional_flag) {
                    playsound = true;
                  }
                  changed = true;
                }
              }
              if (change.type === "modified") {
                let matchIndex = msgs.findIndex((obj) => obj.message_id === data.messageID);
                if (matchIndex !== -1) {
                  const msg = this.mapMessage(data);
                  msgs[matchIndex] = msg;
                  if (msg.creator.lyf_support_professional_flag) {
                    playsound = true;
                  }
                  changed = true;
                }
              }
              if (changed) {
                this.setState(
                  {
                    messages: msgs,
                  },
                  () => {
                    if (this.isLastMessageVisible) {
                      this.scrollToBottom();
                    } else {
                      this.setState({ newMessage: true });
                    }
                  }
                );
                if (playsound) {
                  this.playSound();
                }
              }
            });
          });
        }
      }
    }
  }

  render() {
    var decryptedReplyText = this.state.replyMessageSelected ? this.state.replyMessageSelected.text : "";
    return (
      <>
        {this.props.selectedThread === null && <div className="h-100 chatList empty"></div>}
        {this.props.selectedThread && (
          <div className="cellHolder d-flex flex-column ms-1 shadow" style={{ height: "99%" }}>
            <div
              style={{
                backgroundColor: "#e0e0e0",
                borderTopLeftRadius: "15px",
                borderTopRightRadius: "15px",
              }}
            >
              <Toolbar expiration_date={this.state.expiration_date} refreshMessages={this.reloadSession} />
            </div>
            {this.state.reload && (
              <div className="d-flex h-100 flex-column justify-content-center align-items-center">
                <div>
                  <Loader type="TailSpin" color="green" height={40} width={40} />
                </div>
              </div>
            )}
            {!this.state.reload && (
              <div className="ps-3 pe-3 pt-1 position-relative mb-auto" style={{ overflowY: "auto", overflowX: "auto" }}>
                <ul className="list-unstyled">
                  {this.state.messages &&
                    this.props.selectedThread.status_id !== 2 &&
                    this.state.messages.map((message, index, row) => {
                      if (index + 1 === row.length) {
                        return (
                          <div
                            ref={(el) => {
                              if (el) {
                                this.observer.disconnect();
                                this.observer.observe(el);
                              }
                            }}
                          >
                            <MessageCell
                              key={message.message_id}
                              thread={this.props.selectedThread}
                              message={message}
                              selectReplyMessage={this.selectReplyMessage}
                            />
                          </div>
                        );
                      } else {
                        return (
                          <MessageCell
                            key={message.message_id}
                            thread={this.props.selectedThread}
                            message={message}
                            selectReplyMessage={this.selectReplyMessage}
                          />
                        );
                      }
                    })}
                  {this.state.is_typing === true && <div className="typing">Typing...</div>}
                  <div
                    ref={(el) => {
                      this.el = el;
                    }}
                  />
                </ul>
                {this.state.newMessage === true && (
                  <span className="position-sticky bottom-0 start-50 translate-middle-x pb-3">
                    <Button
                      variant="contained"
                      endIcon={<Icon>expand_more</Icon>}
                      style={{ borderRadius: "20px" }}
                      onClick={() => this.scrollToBottom()}
                    >
                      New message
                    </Button>
                  </span>
                )}
              </div>
            )}
            {this.state.replyMessageSelected && (
              <div className="d-flex me-5 ms-2 mb-1 mt-2">
                <div
                  className="card me-1 align-self-start "
                  style={{ backgroundColor: "#CBCBCC", minWidth: "20%", maxWidth: "80%", borderRadius: "15px" }}
                >
                  <div className="d-flex">
                    <div style={{ overflowX: "auto" }}>
                      <div className="ms-2 mt-1 me-1 text-start fw-bold" style={{ color: "#004950" }}>
                        {this.state.replyMessageSelected.creator.user_id === this.context.user.user_id
                          ? "You"
                          : this.state.replyMessageSelected.creator.display_name}
                      </div>
                      <div
                        className="ms-2 mb-1 me-1 text-start"
                        style={{ color: "#4A4A4A", whiteSpace: "nowrap", overflowX: "hidden", textOverflow: "ellipsis" }}
                      >
                        {this.state.replyMessageSelected.attachments &&
                        this.state.replyMessageSelected.attachments.length > 0 &&
                        decryptedReplyText === ""
                          ? "Image"
                          : decryptedReplyText}
                      </div>
                    </div>
                    {this.state.replyMessageSelected.attachments && this.state.replyMessageSelected.attachments.length > 0 && (
                      <div className="ms-auto mt-auto mb-auto me-2 flex-shrink-0" style={{ maxWidth: "50%" }}>
                        <AttachmentViewer attachments={this.state.replyMessageSelected.attachments} nowrap={false} width="50px" height="50px" />
                      </div>
                    )}
                  </div>
                </div>
                <IconButton className="align-self-center" onClick={this.deleteReplyMessage} style={{ height: 20 }}>
                  <img src="/X_Icon.svg" width={"20"} />
                </IconButton>
              </div>
            )}
            <div
              className="d-flex flex-column justify-content-start"
              style={{
                backgroundColor: "#e0e0e0",
                overflowY: "auto",
                overflowX: "auto",
                minHeight: "130px",
                Height: "130px",
              }}
            >
              {this.props.selectedThread && (
                <ChatBox
                  replyMessageSelected={this.state.replyMessageSelected}
                  deleteReplyMessage={this.deleteReplyMessage}
                  plainReplyText={decryptedReplyText}
                  thread={this.props.selectedThread}
                  typingChanged={this.typingChanged}
                />
              )}
            </div>
          </div>
        )}
      </>
    );
  }
}

export default CenterNav;
