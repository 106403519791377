import React from "react";
import StaffCell from "./StaffCell.js";
import Loader from "react-loader-spinner";
import { Card } from "react-bootstrap";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import "../App.css";
import { AuthContext } from "../Contexts/authContext";
class StaffList extends React.Component {
  static contextType = AuthContext;
  render() {
    return (
      <Card className="h-100">
        <Card.Body className="h-100">
          <Card.Title>Staff</Card.Title>
          <List sx={{ height: "90%", overflow: "auto" }}>
            {this.context.staffLoading === true && (
              <div className="loader">
                <Loader type="TailSpin" color="green" height={40} width={40} />
              </div>
            )}
            {this.context.staffLoading === false && this.context.staffUsers && this.context.staffUsers.length === 0 && (
              <div className="loader">
                <div>One is the loneliest number.</div>
              </div>
            )}
            {this.context.staffLoading === false &&
              this.context.staffUsers &&
              this.context.staffUsers.map((user, i) => {
                return (
                  <>
                    {i > 0 && <Divider variant="inset" component="li" />}
                    <StaffCell key={user.user_id} user={user} />
                  </>
                );
              })}
          </List>
        </Card.Body>
      </Card>
    );
  }
}

export default StaffList;
